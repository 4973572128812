import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentQuestion: 0,
  correctAnswers: [],
  score: 0,
  time: { quiz: 3000, tapping: 600, quizbonus: 600 },
  showIntro: true,
  showResult: false,
  showTrivia: false,
  showMilestone: false,
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    currentQuestionReducer: (state, action) => {
      state.currentQuestion = action.payload;
    },

    correctAnswersReducer: (state, action) => {
      state.correctAnswers.push(action.payload);
    },

    scoreReducer: (state, action) => {
      if (action.payload === "tapping") {
        state.score += 1;
      } else if (action.payload === "hint") {
        state.score -= 50;
      } else if (action.payload === "raketni") {
        state.score += 150;
      } else if (action.payload === "10inrow") {
        state.score += 250;
      } else {
        state.score += action.payload;
      }
    },

    timeReducer: (state, action) => {
      if (action.payload === "quiz") {
        state.time.quiz -= 1;
      }
      if (action.payload === "tapping") {
        state.time.tapping -= 1;
      }
      if (action.payload === "quizbonus") {
        state.time.quizbonus -= 1;
      }
    },

    showIntroReducer: (state, action) => {
      state.showIntro = action.payload;
    },

    showResultReducer: (state, action) => {
      state.showResult = action.payload;
    },

    showTriviaReducer: (state, action) => {
      state.showTrivia = action.payload;
    },

    showMilestoneReducer: (state, action) => {
      state.showMilestone = action.payload;
    },

    reset: () => initialState,
  },
});

export const {
  currentQuestionReducer,
  correctAnswersReducer,
  scoreReducer,
  timeReducer,
  showIntroReducer,
  showResultReducer,
  showTriviaReducer,
  showMilestoneReducer,
  reset,
} = gameSlice.actions;

export default gameSlice.reducer;
