import { useState, useEffect } from "react";
import { useGamesQuery } from "services/gameApi";
import { useSelector } from "react-redux";

import imgTime from "assets/images/icon-time.svg";
import imgScore from "assets/images/icon-score.svg";
import imgSuccess from "assets/images/icon-success.svg";

const StatsGlobal = () => {
  const [sumScore, setSumScore] = useState();
  const [sumTime, setSumTime] = useState();
  const [sumCorrect, setSumCorrect] = useState();

  const { data } = useGamesQuery({});
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    if (data !== undefined) {
      data.users.map((obj) => {
        if (obj.token === token) {
          setSumScore(calculateSum(obj.settings.games, "score"));
          setSumTime(calculateSum(obj.settings.games, "time"));

          // correct answers:
          //setSumCorrect(calculateSum(obj.settings.games, "correctNum"));

          //all answers
          const calculateAllAnswered = obj.settings.games
            .filter(
              (item) =>
                item.id === "game1" ||
                item.id === "game2" ||
                item.id === "game3" ||
                item.id === "game4" ||
                item.id === "game5" ||
                item.id === "game6"
            )
            .reduce((accumulator, object) => {
              let answeredNum = object.correct.length;

              return accumulator + answeredNum;
            }, 0);
          setSumCorrect(calculateAllAnswered);
        }
      });
    }
  }, [data]);

  return (
    <div className="stats-global">
      <div className="row">
        <div className="col-4">
          <div className="stat bg-gradient-gold px-2 pb-2 pt-2 text-white">
            <p className="mb-2">Skupen pretečen čas</p>
            <div className="inside bg-gradient-gold-light">
              <h2 className="text-green mb-0 py-2 d-flex justify-content-center">
                <img className="me-3" src={imgTime} />

                <span>
                  {("0" + Math.floor((sumTime / 600) % 60)).slice(-2)}:
                </span>
                <span>{("0" + Math.floor((sumTime / 10) % 60)).slice(-2)}</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="stat bg-gradient-gold px-2 pb-2 pt-2 text-white">
            <p className="mb-2">Vse zbrane točke</p>
            <div className="inside bg-gradient-gold-light">
              <h2 className="text-green mb-0 py-2 d-flex justify-content-center">
                <img className="me-3" src={imgScore} />
                {sumScore}
              </h2>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="stat bg-gradient-gold px-2 pb-2 pt-2 text-white">
            <p className="mb-2">Odgovorjenih vprašanj</p>
            <div className="inside bg-gradient-gold-light">
              <h2 className="text-green mb-0 py-2 d-flex justify-content-center">
                <img className="me-3" src={imgSuccess} />
                {isWhatPercentOf(sumCorrect, 60).toFixed(0)} %
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function calculateSum(array, property) {
  const total = array.reduce((accumulator, object) => {
    return accumulator + object[property];
  }, 0);

  return Math.trunc(total);
}

function isWhatPercentOf(numA, numB) {
  return (numA / numB) * 100;
}

export default StatsGlobal;
