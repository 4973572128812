import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import gameSlice from "./gameSlice";
import userSlice from "./userSlice";
import resultsSlice from "./resultsSlice";
import { gameApi } from "services/gameApi";

const reducers = combineReducers({
  game: gameSlice,
  user: userSlice,
  results: resultsSlice,
  [gameApi.reducerPath]: gameApi.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: [gameApi.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  //middleware: [thunk],

  middleware: (getdefaultMiddleware) =>
    getdefaultMiddleware({
      serializableCheck: false,
    }).concat([thunk, gameApi.middleware]),
});

export default store;
