// Import React stuff
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { reset } from "store/gameSlice";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import "i18n/config";
import { ReactInternetSpeedMeter } from "react-internet-meter";

// Styles
import "./assets/styles/style.scss";

// Import views and components
import Start from "views/Intro/Start/Start";
import Logo from "views/Intro/Logo/Logo";
import Intro from "views/Intro/Intro";
import GameBanknote from "views/GameBanknote/GameBanknote";
import Dashboard from "views/Dashboard/Dashboard";
import GameQuiz from "views/GameQuiz/GameQuiz";
import GameBonus from "views/GameBonus/GameBonus";

import FixedTopBar from "components/FixedTopBar/FixedTopBar";
import FixedBottomBar from "components/FixedBottomBar/FixedBottomBar";
import Leaderboard from "views/Leaderboard/Leaderboard";
import FinalCode from "views/FinalCode/FinalCode";
import FinalCodeReveal from "views/FinalCode/FinalCodeReveal";
import Admin from "views/Admin/Admin";

// Import media
//import soundBG from "./assets/sounds/bg.mp3";

function App() {
  const [wifiSpeed, setwifiSpeed] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();

  const renders = useRef(0);
  console.log("renders: ", renders.current++);

  useEffect(() => {
    //window.addEventListener("resize", documentHeight);
    //documentHeight();
    // Reset states - for dev
    //dispatch(reset());
  }, []);

  console.log(wifiSpeed);

  return (
    <>
      <ReactInternetSpeedMeter
        txtSubHeading="Spletna povezava je šibka. "
        outputType="alert"
        customClassName={null}
        txtMainHeading="Opps..."
        pingInterval={10000} // milliseconds
        thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
        threshold={4}
        imageUrl="https://escapebox.si/wp-content/uploads/2023/11/Citroen-Turbo-Avto-Kviz1b.jpg"
        downloadSize="1363237" //bytes
        callbackFunctionOnNetworkDown={(speed) =>
          console.log(`Internet speed is down ${speed}`)
        }
        callbackFunctionOnNetworkTest={(speed) => setwifiSpeed(speed)}
      />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Start />} />
          <Route path="/logo" element={<Logo />} />
          <Route
            path="/intro/:intro"
            element={
              <>
                <FixedTopBar />
                <Intro />
                <FixedBottomBar />
              </>
            }
          />
          <Route
            path="/dashboard"
            element={
              <>
                <FixedTopBar />
                <Dashboard />
                <FixedBottomBar />
              </>
            }
          />
          <Route
            path="/banknote"
            element={
              <>
                <FixedTopBar />
                <GameBanknote />
                <FixedBottomBar />
              </>
            }
          />
          <Route
            path="game/:game"
            element={
              <>
                <FixedTopBar />
                <GameQuiz />
                <FixedBottomBar />
              </>
            }
          />
          <Route
            path="gamebonus/:gamebonus"
            element={
              <>
                <FixedTopBar />
                <GameBonus />
                <FixedBottomBar />
              </>
            }
          />
          <Route
            path="/finalcode"
            element={
              <>
                <FixedTopBar />
                <FinalCode />
                <FixedBottomBar />
              </>
            }
          />
          <Route
            path="/finalcodereveal"
            element={
              <>
                <FixedTopBar />
                <FinalCodeReveal />
                <FixedBottomBar />
              </>
            }
          />
          <Route
            path="/leaderboard"
            element={
              <>
                <FixedTopBar />
                <Leaderboard />
                <FixedBottomBar />
              </>
            }
          />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
