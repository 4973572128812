import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgLogoBs from "assets/images/logo-bs.svg";
import imgLogoEb from "assets/images/logo-eb.svg";
import imgHero from "assets/images/hero-image.svg";

import ImgP0 from "assets/images/preizkusi/game0-badge.svg";
import ImgP1 from "assets/images/preizkusi/game1-badge.svg";
import ImgP2 from "assets/images/preizkusi/game2-badge.svg";
import ImgP3 from "assets/images/preizkusi/game3-badge.svg";
import ImgP4 from "assets/images/preizkusi/game4-badge.svg";
import ImgP5 from "assets/images/preizkusi/game5-badge.svg";
import ImgP6 from "assets/images/preizkusi/game6-badge.svg";

import ImgQ1 from "assets/images/question-1.svg";
import ImgQ2 from "assets/images/question-2.svg";
import ImgQ3 from "assets/images/question-4.svg";

const Start = ({ step, setStep }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToLogo = () => {
    setTimeout(() => {
      navigate("../logo", { replace: true });
    }, 1000);
  };

  const logoAnimation = {
    hidden: { opacity: 0, y: "-100vh", scale: 0 },
    show: {
      opacity: 1,

      y: "0",
      scale: 1,
      transition: {
        duration: 2,
        type: "spring",
        staggerChildren: 0.2,
        delayChildren: 1,
      },
    },
  };

  const centerPosition = {
    scale: 1,
    opacity: 0,
    left: "50%",
    top: "50%",
    translateX: "-50%",
    translateY: "-50%",
  };

  const itemA = {
    hidden: centerPosition,
    show: {
      scale: 0.35,
      opacity: 1,
      left: "60%",
      top: "-10%",
    },
  };

  const itemB = {
    hidden: centerPosition,
    show: {
      scale: 0.45,
      opacity: 1,
      left: "20%",
      top: "70%",
    },
  };

  const itemC = {
    hidden: centerPosition,
    show: {
      scale: 0.6,
      opacity: 1,
      left: "80%",
      top: "80%",
    },
  };

  const itemD = {
    hidden: centerPosition,
    show: {
      scale: 0.3,
      opacity: 1,
      left: "70%",
      top: "45%",
    },
  };

  const itemE = {
    hidden: centerPosition,
    show: {
      scale: 0.35,
      opacity: 1,
      left: "90%",
      top: "10%",
    },
  };

  const itemF = {
    hidden: centerPosition,
    show: {
      scale: 0.3,
      opacity: 1,
      left: "10%",
      top: "40%",
    },
  };

  const itemG = {
    hidden: centerPosition,
    show: {
      scale: 0.5,
      opacity: 1,
      left: "20%",
      top: "5%",
    },
  };

  const itemH = {
    hidden: centerPosition,
    show: {
      scale: 1.5,
      opacity: 1,
      left: "40%",
      top: "2%",
    },
  };
  const itemI = {
    hidden: centerPosition,
    show: {
      scale: 1.2,
      opacity: 1,
      left: "77%",
      top: "2%",
    },
  };
  const itemJ = {
    hidden: centerPosition,
    show: {
      scale: 1.2,
      opacity: 1,
      left: "90%",
      top: "40%",
    },
  };

  return (
    <div className="start-container intro theme-gold">
      <Wrapper className="start w-100 text-center">
        <div className="flex-grow-1">
          <div className="logos d-flex align-items-center p-3">
            <div className="me-auto ">
              <img src={imgLogoBs} />
            </div>
            <div>
              <img src={imgLogoEb} />
            </div>
          </div>
          <motion.div
            className=" mt-2 introanimation position-relative"
            variants={logoAnimation}
            initial="hidden"
            animate="show"
            onAnimationComplete={() => console.log("END")}>
            <img className="w-75 " src={imgHero} />

            <motion.img
              src={ImgP0}
              style={{ position: "absolute", zIndex: "0" }}
              variants={itemA}
            />
            <motion.img
              src={ImgP1}
              style={{ position: "absolute", zIndex: "0" }}
              variants={itemB}
            />
            <motion.img
              src={ImgP2}
              style={{ position: "absolute", zIndex: "0" }}
              variants={itemC}
            />

            <motion.img
              src={ImgP3}
              style={{ position: "absolute", zIndex: "0" }}
              variants={itemD}
            />

            <motion.img
              src={ImgP4}
              style={{ position: "absolute", zIndex: "0" }}
              variants={itemE}
            />

            <motion.img
              src={ImgP5}
              style={{ position: "absolute", zIndex: "0" }}
              variants={itemF}
            />
            <motion.img
              src={ImgP6}
              style={{ position: "absolute", zIndex: "0" }}
              variants={itemG}
            />

            <motion.img
              src={ImgQ1}
              style={{ position: "absolute", zIndex: "0" }}
              variants={itemH}
            />
            <motion.img
              src={ImgQ2}
              style={{ position: "absolute", zIndex: "0" }}
              variants={itemI}
            />
            <motion.img
              src={ImgQ3}
              style={{ position: "absolute", zIndex: "0" }}
              variants={itemJ}
            />
          </motion.div>

          <h1 className="text-green mb-2 text-uppercase">
            Atlantova
            <br />
            Skrivnost
          </h1>
          <p className="fw-bold">
            Igra iskanja zaklada
            <br />v muzeju Banke Slovenije.
          </p>
        </div>
        <Button
          label="Začni igro"
          className="w-50 mx-auto mb-5"
          onClick={() => navigate("/logo")}
        />
      </Wrapper>
    </div>
  );
};

export default Start;
