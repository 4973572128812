const QuizPagination = ({
  data,
  gamesData,
  totalQuestionsNum,
  correctAnswers,
  currentQuestion,
}) => {
  return (
    <div className={`progress-square d-inline-flex mt-5 ${gamesData.id}`}>
      <div className="line"></div>
      {data
        .filter((status, index) => index <= totalQuestionsNum - 1)
        .map((status, index) => (
          <div
            key={index}
            className={`sq ${
              correctAnswers[index] === true
                ? "correct"
                : correctAnswers[index] === false
                ? "incorrect"
                : index === currentQuestion
                ? "active"
                : null
            }`}></div>
        ))}
    </div>
  );
};

export default QuizPagination;
