import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { reset } from "store/gameSlice";
// Import content

// Import quiz
import GameBanknoteGame from "components/GameBanknoteGame/GameBanknoteGame";
import GameBanknoteIntro from "./GameBanknoteIntro/GameBanknoteIntro";
import GameBanknoteResult from "./GameBanknoteResult/GameBanknoteResult";

const GameBanknote = () => {
  const [noteItems, setNoteItems] = useState({
    stevilka: "",
    crtice: "",
    portret: "",
    arhitekt: "",
    mavricne: "",
    nit: "",
    vodniznak: "",
    color: "",
    icon: [],
  });

  const showIntro = useSelector((state) => state.game.showIntro);
  const showResult = useSelector((state) => state.game.showResult);

  const dispatch = useDispatch();

  return (
    <div className="main-container">
      <AnimatePresence mode="wait">
        {showIntro ? (
          <GameBanknoteIntro key="intro" />
        ) : showResult ? (
          <GameBanknoteResult noteItems={noteItems} key="result" />
        ) : (
          <GameBanknoteGame
            noteItems={noteItems}
            setNoteItems={setNoteItems}
            key="banknote"
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default GameBanknote;
