import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "store/gameSlice";

import { motion, AnimatePresence } from "framer-motion";
import {
  useGamesQuery,
  useUpdateGameMutation,
  useUpdateTeamMutation,
} from "services/gameApi";

import { staggerItemsFade, itemFade } from "utils/animations";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import StatsGlobal from "components/StatsGlobal/StatsGlobal";
import DashBoardStatusPills from "./DashBoardStatusPills";

import { ReactComponent as ImgP1 } from "assets/images/preizkusi/game1-badge.svg";
import { ReactComponent as ImgP2 } from "assets/images/preizkusi/game2-badge.svg";
import { ReactComponent as ImgP3 } from "assets/images/preizkusi/game3-badge.svg";
import { ReactComponent as ImgP4 } from "assets/images/preizkusi/game4-badge.svg";
import { ReactComponent as ImgP5 } from "assets/images/preizkusi/game5-badge.svg";
import { ReactComponent as ImgP6 } from "assets/images/preizkusi/game6-badge.svg";

import imgMilestone1 from "assets/images/icon-milestone1.svg";
import imgMilestone2 from "assets/images/icon-milestone2.svg";
import imgMilestone3 from "assets/images/icon-milestone3.svg";
import imgLock from "assets/images/lock.svg";
import imgUnLock from "assets/images/unlock.svg";
import { gamesData } from "../GameQuiz/gamesData";

const Dashboard = () => {
  const [showTenInRow, setShowTenInRow] = useState(false);
  const [showRaketniTek, setShowRaketniTek] = useState(false);
  const [showHitriPrstki, setShowHitriPrstki] = useState(false);

  const [samneki, setSamNeki] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const token = useSelector((state) => state.user.token);

  const { data, error, isLoading, isSuccess, refetch } = useGamesQuery();

  const [updateGame] = useUpdateGameMutation();
  const [updateTeam] = useUpdateTeamMutation();

  const gameSvgs = {
    game1: ImgP1,
    game2: ImgP2,
    game3: ImgP3,
    game4: ImgP4,
    game5: ImgP5,
    game6: ImgP6,
  };

  function GameSVG({ id }) {
    // Correct! JSX type can be a capitalized variable.
    const SpecificSVG = gameSvgs[id];
    return <SpecificSVG className="game-icon" />;
  }

  const handleStartGame = (game, status, user) => {
    console.log("obj", data.stats.games);
    const filteredGameStatus = data.stats.games.map((obj) => {
      if (obj.id === game) {
        return { ...obj, status: "closed" };
      }
      return obj;
    });

    if (user === "open" /* && user !== "completed" */) {
      const newStatus = { ...data, stats: { games: filteredGameStatus } };
      navigate(`/game/${game}`);
      updateGame(newStatus);
      updateTeamData(game);
    } else {
      console.log("NOT AVAILABLE");
    }
  };

  const updateTeamData = (gameID) => {
    const filteredTeamStatus = data.users
      .filter((i) => i.token === token)
      .map((obj) => {
        const settingsGame = obj.settings.games.map((game) => {
          if (game.id === gameID) {
            return {
              ...game,
              status: "playing",
            };
          }
          return game;
        });

        return {
          ...obj,
          settings: { ...obj.settings, games: settingsGame },
        };
      });

    updateTeam(filteredTeamStatus[0]);
  };

  const checkAvailability = (game, status) => {
    const check = data.users.some((user) =>
      user.settings.games.some(
        (item) => item.id === game && item.status === "playing"
      )
    );

    // console.log("data.users", data.users);

    if (status === "completed") {
      return "completed";
    } /* else if (check) {
      return "closed";
    }  */ else {
      return "open";
    }
  };

  useEffect(() => {
    dispatch(reset());

    const interval = setInterval(() => {
      refetch();
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    console.log("datalive", data);
    if (data !== undefined) {
      data.users.map((obj) => {
        if (obj.token === token) {
          const mergedCorrect = [
            ...obj.settings.games[0].correct,
            ...obj.settings.games[1].correct,
            ...obj.settings.games[2].correct,
            ...obj.settings.games[3].correct,
            ...obj.settings.games[4].correct,
            ...obj.settings.games[5].correct,
          ];

          if (getNumberOfTruthyInRow(mergedCorrect) >= 10) {
            setShowTenInRow(true);
          }

          // If all games completed go to key
          if (allAreTrue(obj.settings.games)) {
            navigate(`/finalcode`);
          }

          const isQuizBonus1Complete = obj.settings.games[6].status;
          const isQuizBonus2Complete = obj.settings.games[7].status;
          const isQuizBonus3Complete = obj.settings.games[8].status;
          const isTappingComplete = obj.settings.games[9].status;

          obj.settings.games.map((game) => {
            if (game.time !== null && game.time < 1200) {
              setShowRaketniTek(true);
            }

            if (game.id === "tapping" && game.status === "completed") {
              setShowHitriPrstki(true);
            }

            // Redirct back to game if game is not completed
            if (game.status === "playing") {
              navigate(`/game/${game.id}`);
            }

            if (
              game.id === "game2" &&
              game.status === "completed" &&
              isQuizBonus3Complete === "open"
            ) {
              navigate(`/gamebonus/quizbonus3`);
            }

            if (
              game.id === "game4" &&
              game.status === "completed" &&
              isQuizBonus2Complete === "open"
            ) {
              navigate(`/gamebonus/quizbonus2`);
            }

            if (
              game.id === "game6" &&
              game.status === "completed" &&
              isQuizBonus1Complete === "open"
            ) {
              navigate(`/gamebonus/quizbonus1`);
            }

            if (
              game.id === "game3" &&
              game.status === "completed" &&
              isTappingComplete === "open"
            ) {
              navigate(`/gamebonus/tapping`);
            }
          });
        }
      });
    }
  }, [data]);

  return (
    <div className="main-container">
      <Wrapper className="dashboard position-relative">
        <AnimatePresence mode="wait">
          <motion.div
            className="d-flex flex-column h-100 pt-1"
            variants={staggerItemsFade}
            initial="hidden"
            animate="show"
            exit="hidden">
            <motion.div variants={itemFade}>
              <StatsGlobal />
            </motion.div>

            <div className="flex-grow-1 d-flex align-items-center badges bg-white shadow-wrapper shadow-wrapper__bg-grey pt-4 mt-2 px-5">
              <div className="row">
                {isLoading && <p>Loading...</p>}
                {error && <p>An error occured</p>}

                {isSuccess && (
                  <>
                    {data.stats.games.map((game) => (
                      <motion.div key={game.id} className="col-4 mb-5">
                        {data.users
                          .filter((i) => i.token === token)
                          .map((obj) => {
                            const settingsGame = obj.settings.games.map(
                              (user) => {
                                if (user.id === game.id) {
                                  return (
                                    <motion.div
                                      variants={itemFade}
                                      key={user.id}
                                      onClick={() =>
                                        handleStartGame(
                                          game.id,
                                          game.status,
                                          user.status
                                        )
                                      }
                                      className={`${
                                        game.id
                                      } ${checkAvailability(
                                        game.id,
                                        user.status
                                      )} `}>
                                      <GameSVG id={game.id} />
                                      <p className="mt-1 mb-0 fw-bold">
                                        {game.name}
                                      </p>

                                      <DashBoardStatusPills
                                        data={user}
                                        show={`${checkAvailability(
                                          game.id,
                                          user.status
                                        )}`}
                                      />
                                    </motion.div>
                                  );
                                }
                              }
                            );

                            return settingsGame;
                          })}
                      </motion.div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="milestones mt-3">
              <p className="fw-bold text-start">{t("DashH1")}</p>
              <div className="row g-3">
                <div className="col-4">
                  <div
                    style={{ height: "120px" }}
                    className="bg-grey bg-opacity-25  rounded-1">
                    <motion.div
                      className="h-100 position-relative"
                      variants={itemFade}>
                      {!showTenInRow ? (
                        <img className="lock" src={imgLock} />
                      ) : (
                        <img className="lock" src={imgUnLock} />
                      )}
                      <div
                        className={`bg-gold ${
                          !showTenInRow && "opacity-50"
                        }  h-100 p-3 d-flex align-items-center text-start text-white rounded-1`}>
                        <div className="circle-icon circle-icon__small d-flex align-items-center justify-content-center me-3">
                          <img src={imgMilestone1} />
                        </div>

                        <div>
                          <h4 className="text-white mb-1">10 v vrsto</h4>
                          <p
                            style={{ fontSize: "0.775em" }}
                            className="mb-0 small">
                            {t("Dash10zaporedP")}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>

                <div className="col-4">
                  <div
                    style={{ height: "120px" }}
                    className="bg-grey bg-opacity-25  rounded-1">
                    <motion.div
                      className="h-100 position-relative"
                      variants={itemFade}>
                      {!showRaketniTek ? (
                        <img className="lock" src={imgLock} />
                      ) : (
                        <img className="lock" src={imgUnLock} />
                      )}

                      <div
                        className={`bg-gold ${
                          !showRaketniTek && "opacity-50"
                        }  h-100 p-3 d-flex align-items-center text-start text-white rounded-1`}
                        variants={itemFade}>
                        <div className="circle-icon circle-icon__small d-flex align-items-center justify-content-center me-3">
                          <img src={imgMilestone2} />
                        </div>

                        <div>
                          <h4 className="text-white mb-1">Raketni tek</h4>
                          <p
                            style={{ fontSize: "0.775em" }}
                            className="mb-0 small">
                            {t("DashRaketniP")}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>

                <div className="col-4">
                  <div
                    style={{ height: "120px" }}
                    className={`bg-grey bg-opacity-25  rounded-1`}>
                    <motion.div
                      className="h-100 position-relative"
                      variants={itemFade}>
                      {!showHitriPrstki ? (
                        <img className="lock" src={imgLock} />
                      ) : (
                        <img className="lock" src={imgUnLock} />
                      )}

                      <div
                        className={`bg-gold ${
                          !showHitriPrstki && "opacity-50"
                        }  h-100 p-3 d-flex align-items-center text-start text-white rounded-1`}>
                        <div className="circle-icon circle-icon__small d-flex align-items-center justify-content-center me-3">
                          <img src={imgMilestone3} />
                        </div>

                        <div>
                          <h4 className="text-white mb-1">Hitri prstki</h4>
                          <p
                            style={{ fontSize: "0.775em" }}
                            className="mb-0 small">
                            {t("DashHitriP")}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </Wrapper>
    </div>
  );
};

function getNumberOfTruthyInRow(arr) {
  let curr = 0;
  let maxLen = 1;
  let last = true;
  for (const elem of arr) {
    if (elem === last) maxLen = Math.max(maxLen, ++curr);
    else curr = 0;
  }
  return maxLen;
}

function allAreTrue(arr) {
  return arr.every((element) => element.status === "completed");
}

export default Dashboard;
