import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as ImgTime } from "assets/images/icon-time.svg";
import { ReactComponent as ImgSuccess } from "assets/images/icon-success.svg";

const DashBoardStatusPills = ({ show, data }) => {
  const { t } = useTranslation();

  let correctAnswersNum = data.correct.filter((x) => x === true).length;

  return (
    <>
      {show === "completed" && (
        <>
          <span className="badge d-inline-flex align-items-center justify-content-center me-1">
            <ImgTime />

            <span>{("0" + Math.floor((data.time / 600) % 60)).slice(-2)}:</span>
            <span>{("0" + Math.floor((data.time / 10) % 60)).slice(-2)}</span>
          </span>
          <span className="badge d-inline-flex align-items-center justify-content-center me-1">
            <ImgSuccess />
            {correctAnswersNum}/10
          </span>
        </>
      )}

      {show === "open" && (
        <span className="badge d-inline-flex align-items-center justify-content-center me-1">
          PROSTO
        </span>
      )}

      {show === "closed" && (
        <span className="badge d-inline-flex align-items-center justify-content-center me-1">
          ZASEDENO
        </span>
      )}
    </>
  );
};

export default DashBoardStatusPills;
