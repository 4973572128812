import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";

import { timeReducer, scoreReducer, showResultReducer } from "store/gameSlice";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import CurrentGameStatus from "components/CurrentGameStatus/CurrentGameStatus";

import imgIntro from "assets/images/bonus-tapping-intro.png";
import imgTapIcon from "assets/images/icon-tap.svg";
import imgCoin2 from "assets/images/coin2.svg";

const FastTapping = ({ gamesData }) => {
  const dispatch = useDispatch();
  const timeLeft = useSelector((state) => state.game.time.tapping);
  const score = useSelector((state) => state.game.score);

  let width = window.innerWidth;
  let height = window.innerHeight;
  const body = document.body;

  const elButton = document.querySelector(".action-button");
  const elWrapper = document.querySelector(".action-wrapper");

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  //document.getElementById("f_name_mark").innerHTML = "<img src='images/icons/tick.png' class='mark'>";

  const treatmojis = [
    "<img src='/coin1.png' className='coin'>",
    "<img src='/coin2.png' className='coin'>",
    "<img src='/coin3.png' className='coin'>",
    "<img src='/coin4.png' className='coin'>",
  ];
  const treats = [];
  const radius = 45;

  const Cd = 0.47; // Dimensionless
  const rho = 0.22; // kg / m^3
  const A = (Math.PI * radius * radius) / 10000; // m^2
  const ag = 6.81; // m / s^2
  const frameRate = 1 / 50;

  function createTreat() /* create a treat */ {
    const vx = getRandomArbitrary(-10, 10); // x velocity
    const vy = getRandomArbitrary(-10, 1); // y velocity

    const el = document.createElement("div");
    el.className = "treat";

    const inner = document.createElement("span");
    inner.className = "inner";
    //inner.innerText = treatmojis[getRandomInt(0, treatmojis.length - 1)];

    inner.innerHTML = treatmojis[getRandomInt(0, treatmojis.length - 1)];

    el.appendChild(inner);

    elWrapper.appendChild(el);

    const rect = el.getBoundingClientRect();

    const lifetime = getRandomArbitrary(1000, 1500);

    el.style.setProperty("--lifetime", lifetime);

    const treat = {
      el,
      absolutePosition: { x: rect.left, y: rect.top },
      position: { x: rect.left, y: rect.top },
      velocity: { x: vx, y: vy },
      mass: 0.1, //kg
      radius: el.offsetWidth, // 1px = 1cm
      restitution: -0.7,

      lifetime,
      direction: vx > 0 ? 1 : -1,

      animating: true,

      remove() {
        this.animating = false;
        this.el.parentNode.removeChild(this.el);
      },

      animate() {
        const treat = this;
        let Fx =
          (-0.5 *
            Cd *
            A *
            rho *
            treat.velocity.x *
            treat.velocity.x *
            treat.velocity.x) /
          Math.abs(treat.velocity.x);
        let Fy =
          (-0.5 *
            Cd *
            A *
            rho *
            treat.velocity.y *
            treat.velocity.y *
            treat.velocity.y) /
          Math.abs(treat.velocity.y);

        Fx = isNaN(Fx) ? 0 : Fx;
        Fy = isNaN(Fy) ? 0 : Fy;

        // Calculate acceleration ( F = ma )
        var ax = Fx / treat.mass;
        var ay = ag + Fy / treat.mass;
        // Integrate to get velocity
        treat.velocity.x += ax * frameRate;
        treat.velocity.y += ay * frameRate;

        // Integrate to get position
        treat.position.x += treat.velocity.x * frameRate * 100;
        treat.position.y += treat.velocity.y * frameRate * 100;

        treat.checkBounds();
        treat.update();
      },

      checkBounds() {
        if (treat.position.y > height - treat.radius) {
          treat.velocity.y *= treat.restitution;
          treat.position.y = height - treat.radius;
        }
        if (treat.position.x > width - treat.radius) {
          treat.velocity.x *= treat.restitution;
          treat.position.x = width - treat.radius;
          treat.direction = -1;
        }
        if (treat.position.x < treat.radius) {
          treat.velocity.x *= treat.restitution;
          treat.position.x = treat.radius;
          treat.direction = 1;
        }
      },

      update() {
        const relX = this.position.x - this.absolutePosition.x;
        const relY = this.position.y - this.absolutePosition.y;

        this.el.style.setProperty("--x", relX);
        this.el.style.setProperty("--y", relY);
        this.el.style.setProperty("--direction", this.direction);
      },
    };

    setTimeout(() => {
      treat.remove();
    }, lifetime);

    return treat;
  }

  function animationLoop() {
    var i = treats.length;
    while (i--) {
      treats[i].animate();

      if (!treats[i].animating) {
        treats.splice(i, 1);
      }
    }

    requestAnimationFrame(animationLoop);
  }

  function addTreats() {
    //cancelAnimationFrame(frame);
    if (treats.length > 10) {
      return;
    }
    for (let i = 0; i < 1; i++) {
      treats.push(createTreat());
    }
  }

  const clickHandler = () => {
    setTimeout(() => {
      dispatch(scoreReducer("tapping"));
    }, 100);

    animationLoop();

    addTreats();
  };

  useEffect(() => {
    if (timeLeft === 0) {
      //alert(taps);
      dispatch(showResultReducer(true));
    }
  }, [timeLeft]);

  // Fast tapping

  return (
    <Wrapper className="bg-blue p-3 shadow-wrapper w-100 text-center position-relative">
      <div className="bg-gradient-gold shadow-wrapper">
        <h2 className="display-6 fw-bold text-white text-uppercase p-3">
          Hitri Prstki
        </h2>
      </div>

      <div className="bg-babyblue bg-opacity-50 h-100 shadow-wrapper mt-3">
        <CurrentGameStatus
          gamesData={gamesData}
          timeLeft={timeLeft}
          scoreBasedOnCorrectAnswers={score}
          setTimeLeft={() => dispatch(timeReducer("tapping"))}
        />

        <div className="action-wrapper h-100 d-flex align-items-center justify-content-center">
          <motion.button
            whileTap={{ scale: 0.9 }}
            onClick={() => clickHandler()}
            className="action-button">
            <img src={imgTapIcon} />
          </motion.button>
        </div>
      </div>
    </Wrapper>
  );
};

export default FastTapping;
