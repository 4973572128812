import { useEffect, useRef } from "react";

export const Timer = ({ timeLeft, setTimeLeft }) => {
  const intervalRef = useRef(); // Add a ref to store the interval id

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft();
    }, 100);
    return () => clearInterval(intervalRef.current);
  }, []);

  // Add a listener to `timeLeft`
  useEffect(() => {
    if (timeLeft <= 0) {
      clearInterval(intervalRef.current);
    }
  }, [timeLeft]);

  return (
    <>
      <span>{("0" + Math.floor((timeLeft / 600) % 60)).slice(-2)}:</span>
      <span>{("0" + Math.floor((timeLeft / 10) % 60)).slice(-2)}</span>
    </>
  );
};
