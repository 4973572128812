import { useContext, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  correctAnswersReducer,
  currentQuestionReducer,
  showResultReducer,
  scoreReducer,
  showMilestoneReducer,
  timeReducer,
} from "store/gameSlice";
import { correctAnswersTotalReducer } from "store/userSlice";

import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { fadeInOut, blurInOut, scaleInOut } from "utils/animations";
import Lottie from "lottie-react";
import { useGamesQuery, useUpdateTeamMutation } from "services/gameApi";
import animationCorrect from "utils/lottie/correct_green.json";
import { Calculator } from "react-mac-calculator";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import CurrentGameStatus from "components/CurrentGameStatus/CurrentGameStatus";
import StatsGlobal from "components/StatsGlobal/StatsGlobal";
import QuizAnswers from "./QuizAnswers";
import QuizQuestion from "./QuizQuestion";
import QuizPagination from "./QuizPagination";

import imgCalc from "assets/images/icon-calculator.svg";
import imgHint from "assets/images/icon-hint.svg";
import imgHintTransparent from "assets/images/icon-hint-transparent.svg";
import { current } from "@reduxjs/toolkit";

function Quiz({ gamesData }) {
  // Shuffle questions
  const [randomizeSteps, setRandomizeSteps] = useState([]);
  // Is current game question correct
  const [isAnswerCorrect, setIsAnswerCorrect] = useState();
  // Set which answer was clicked
  const [answerClicked, setAnswerClicked] = useState(null);
  // Is form submitted
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // If is loading
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);
  // Show Hint toolbar
  const [showHint, setShowHint] = useState(false);
  // Activate Hint
  const [isHintActivated, setIsHintActivated] = useState(false);
  // Video started (for questions with video)
  const [showVideo, setShowVideo] = useState({ container: true, video: false });
  // Show calc
  const [showCalc, setShowCalc] = useState(false);
  // Total user score
  const [totalScore, setTotalScore] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentQuestion = useSelector((state) => state.game.currentQuestion);
  const correctAnswers = useSelector((state) => state.game.correctAnswers);
  const showMilestone = useSelector((state) => state.game.showMilestone);
  const timeLeft = useSelector((state) => state.game.time[gamesData.type]);
  const score = useSelector((state) => state.game.score);
  const token = useSelector((state) => state.user.token);
  const correctAnswersTotal = useSelector(
    (state) => state.user.correctAnswersTotal
  );

  const [updateTeam] = useUpdateTeamMutation();

  const { data, error, isLoading, isSuccess, refetch } = useGamesQuery();

  let correctAnswersNum = correctAnswers.filter((x) => x === true).length;
  let totalQuestionsNum = gamesData.numberOfQuestions;
  let successRate = Math.round((correctAnswersNum / totalQuestionsNum) * 100);

  // Each second left is 1 point
  let scoreBasedOnTime = timeLeft / 10;
  // Each correct answer is 20 points
  let scoreBasedOnCorrectAnswers = correctAnswersNum * 20;

  let calculateScore = scoreBasedOnTime + scoreBasedOnCorrectAnswers;

  // On answer handler
  const handleAnswerOptionClick = (e, isCorrect) => {
    // set clicked button ID and true/false if answer is correct

    setAnswerClicked(e.currentTarget.id);
    setIsAnswerCorrect(isCorrect);
  };

  // On form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();

    setIsFormSubmitted(true);

    // move to next question
    let nextQuestion = currentQuestion + 1;

    // Find correct answer
    let correctAnswerIndex = randomizeSteps[currentQuestion].answers.findIndex(
      (item) => item.isCorrect === true
    );

    // find correct answer, clicked answer and list all buttons to clear classes on next step
    let answerCorrectBtn = document.getElementById(
      `answer_${correctAnswerIndex}`
    );
    let answerClickedBtn = document.getElementById(answerClicked);
    let answersAllButtons = document.querySelectorAll(".answer-section button");

    console.log(answerClickedBtn);
    console.log(answerClicked);

    // add correct or wrong class
    answerClickedBtn.classList.add(isAnswerCorrect ? "correct" : "wrong");

    if (gamesData.id !== "quizbonus") {
      answerCorrectBtn.classList.add(!isAnswerCorrect && "correct");
    }

    // Add true/false to array and send to global state

    dispatch(correctAnswersReducer(isAnswerCorrect));

    // Track answers for 10 in a row milestone (only regular quizes)
    if (gamesData.id !== "quizbonus") {
      dispatch(correctAnswersTotalReducer(isAnswerCorrect));
    }

    // Reset clicked button state
    setAnswerClicked(null);

    // Show milestone modals

    // 10 in a row milestone
    console.log("correctAnswersTotal", correctAnswersTotal);
    var c = 0,
      max = 0;

    correctAnswersTotal.forEach(function (e) {
      e == true ? c++ : (c = 0);
      if (c > max) max = c;
    });

    if (max === 9) {
      dispatch(showMilestoneReducer("10inrow"));
      dispatch(scoreReducer("10inrow"));
      dispatch(correctAnswersTotalReducer("reset"));
    }

    console.log(max);

    //

    // What happens after user submits answer
    setTimeout(
      () => {
        setIsFormSubmitted(false);
        // Reset hint
        setIsHintActivated(false);
        setShowHint(false);

        // Remove classes for next questoon
        answersAllButtons.forEach(function (element) {
          element.classList.remove("correct");
          element.classList.remove("wrong");
        });

        // Move to next question or to success/fail screen
        if (nextQuestion < totalQuestionsNum) {
          dispatch(currentQuestionReducer(nextQuestion));
        } else if (nextQuestion === totalQuestionsNum) {
          dispatch(scoreReducer(calculateScore));
          dispatch(showResultReducer(true));
        }
      },
      gamesData.id === "quizbonus" ? 1000 : 2500
    );
  };

  const handleHintClick = (action) => {
    if (action === "show") {
      setShowHint(true);
    }

    if (action === "confirm") {
      setShowHint(false);
      setIsHintActivated(true);
      dispatch(scoreReducer("hint"));
      setTotalScore(totalScore - 50);
    }

    if (action === "close") {
      setShowHint(false);
    }
  };

  const handleCalcClick = (action) => {
    if (action === "show") {
      setShowCalc(true);
    }

    if (action === "close") {
      setShowCalc(false);
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      dispatch(scoreReducer(calculateScore));
      dispatch(showResultReducer(true));
    }
  }, [timeLeft]);

  useEffect(() => {
    if (isHintActivated) {
      const last = Array.from(document.querySelectorAll(".disable")).pop();
      last.classList.remove("disable");
    }
  }, [isHintActivated]);

  useEffect(() => {
    // Create new array
    const randomizeArray = [];

    // Indexes that will be randomly picked (should do this better)
    let randomQuestionsIndex = [1, 3, 5, 7, 9, 10, 11, 12, 13, 14];
    // Function that randomly pick one number
    let generateQuestionIndex = randomNoRepeats(randomQuestionsIndex);

    // Loop over available steps
    for (var i = 0; i < gamesData.step.length; i++) {
      var item = gamesData.step[i];

      // Shuffle answers
      if (gamesData.type === "quiz" && gamesData.id !== "game6") {
        shuffle(item.answers);
      }

      // Question that will be fixed on specific positions/index
      if (i === 0 || i === 2 || i === 4 || i === 6 || i === 8) {
        randomizeArray.push(item);
      }
      // Question that will be randomly generated
      else {
        randomizeArray.push(gamesData.step[generateQuestionIndex()]);
      }
    }

    // Set new array as state
    if (gamesData.type === "quizbonus") {
      setRandomizeSteps(gamesData.step.sort(() => Math.random() - 0.5));
    } else {
      setRandomizeSteps(randomizeArray);
    }

    // Get user score
    if (isSuccess) {
      const getScore = data.users.filter((i) => i.token === token);
      setTotalScore(getScore[0].score);
    }

    setIsLoadingQuestions(false);
  }, []);

  useEffect(() => {
    console.log("randomizeSteps", randomizeSteps);
  }, [randomizeSteps]);

  return (
    <Wrapper className={`quiz w-100 ${gamesData.id} text-center`}>
      <div
        className={`position-relative p-3 mb-3 flex-grow-1 d-flex flex-column shadow-wrapper ${
          gamesData.id === "quizbonus" ? "bg-green-medium" : "bg-gold-light"
        } `}>
        {isLoadingQuestions ? (
          <h1>LOADING</h1>
        ) : (
          <>
            {gamesData.id === "quizbonus" && (
              <div className="bg-gradient-gold shadow-wrapper">
                <h2 className="display-6 fw-bold text-white text-uppercase p-3">
                  BONUS: Drži, ne drži
                </h2>
              </div>
            )}

            <div>
              <CurrentGameStatus
                gamesData={gamesData}
                successRate={successRate}
                scoreBasedOnCorrectAnswers={scoreBasedOnCorrectAnswers}
                totalScore={totalScore}
                timeLeft={timeLeft}
                isHintActivated={isHintActivated}
                setTimeLeft={() =>
                  dispatch(
                    timeReducer(
                      gamesData.id === "quizbonus" ? "quizbonus" : "quiz"
                    )
                  )
                }
              />

              {gamesData.type === "quiz" && (
                <QuizPagination
                  data={randomizeSteps}
                  gamesData={gamesData}
                  totalQuestionsNum={totalQuestionsNum}
                  correctAnswers={correctAnswers}
                  currentQuestion={currentQuestion}
                />
              )}
            </div>

            <AnimatePresence>
              {showMilestone === "10inrow" && (
                <motion.div
                  className="lightbox milestone deset-v-vrsto raketni-tek rounded-2 d-flex align-items-center justify-content-center "
                  variants={scaleInOut}
                  initial="hidden"
                  animate="visible"
                  exit="exit">
                  <motion.div
                    variants={fadeInOut}
                    initial="hidden"
                    animate="visible"
                    exit="exit">
                    <div className="w-100 px-6 text-center">
                      <h3 className="text-green mb-5">Dosežek odklenjen!</h3>
                      <h3 className="text-white display-5 mb-5">
                        10 zaporednih pravilnih
                        <br />
                        odgovorov = 10 v vrsto!
                      </h3>
                      <h2 className="text-white">
                        Dodatnih 250 točk je vaših!
                      </h2>
                      <Lottie
                        className="w-50 mx-auto"
                        animationData={animationCorrect}
                        loop={false}
                      />
                      <Button
                        label="Naprej"
                        className="bg-green w-100 px-5 py-4 "
                        onClick={() => dispatch(showMilestoneReducer(false))}
                      />
                    </div>
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {showVideo.container &&
                randomizeSteps[currentQuestion].questionImg !== null &&
                randomizeSteps[currentQuestion].questionImg.includes("mp4") && (
                  <motion.div
                    className="lightbox rounded-2 d-flex align-items-center justify-content-center "
                    variants={fadeInOut}
                    initial="hidden"
                    animate="visible"
                    exit="exit">
                    <motion.div
                      variants={fadeInOut}
                      initial="hidden"
                      animate="visible"
                      exit="exit">
                      {showVideo.video ? (
                        <>
                          <video
                            autoPlay
                            onEnded={() =>
                              setShowVideo({ video: false, container: false })
                            }>
                            <source
                              src={
                                process.env.PUBLIC_URL +
                                "/images/quiz/" +
                                gamesData.id +
                                "/" +
                                randomizeSteps[currentQuestion].questionImg
                              }
                              type="video/mp4"
                            />
                          </video>
                        </>
                      ) : (
                        <div className="w-100 text-center">
                          <h3 className="text-white mb-4">Oči na peclje.</h3>
                          <Button
                            label="Oglejte si video"
                            className="bg-green px-5 py-4"
                            onClick={() =>
                              setShowVideo({ ...showVideo, video: true })
                            }
                          />
                        </div>
                      )}
                    </motion.div>
                  </motion.div>
                )}
            </AnimatePresence>

            <form className="flex-grow-1 px-6 mt-6" onSubmit={handleSubmit}>
              <AnimatePresence mode="wait">
                <motion.div
                  className={`h-100 flex-grow-1 d-flex flex-column ${
                    showVideo.video && "pe-none"
                  }`}
                  key={`question-${currentQuestion}`}
                  variants={blurInOut}
                  initial="hidden"
                  animate="visible"
                  exit="exit">
                  <QuizQuestion
                    data={randomizeSteps[currentQuestion]}
                    gamesData={gamesData}
                  />
                  <QuizAnswers
                    gamesData={gamesData}
                    data={randomizeSteps[currentQuestion]}
                    clickHandler={handleAnswerOptionClick}
                    isFormSubmitted={isFormSubmitted}
                    isHintActivated={isHintActivated}
                    isAnswerCorrect={isAnswerCorrect}
                  />
                  {(gamesData.id === "game2" || gamesData.id === "game5") && (
                    <img
                      className="position-absolute calc"
                      style={{ left: "10px", bottom: "10px" }}
                      onClick={() =>
                        showCalc
                          ? handleCalcClick("close")
                          : handleCalcClick("show")
                      }
                      src={imgCalc}
                    />
                  )}
                  <>
                    {showCalc && (
                      <div className="calculator-wrapper">
                        <svg
                          fill="#000000"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 24 24"
                          xmlSpace="preserve">
                          <path d="M5.6,4.2L4.2,5.6l6.4,6.4l-6.4,6.4l1.4,1.4l6.4-6.4l6.4,6.4l1.4-1.4L13.4,12l6.4-6.4l-1.4-1.4L12,10.6L5.6,4.2z" />
                          <rect
                            class="st0"
                            fill="none"
                            width="24"
                            height="24"
                          />
                        </svg>

                        <Calculator />
                      </div>
                    )}
                  </>

                  <>
                    {showHint && (
                      <div
                        className="hint-confirm shadow-wrapper p-3 bg-white position-absolute"
                        style={{ right: "10px", bottom: "10px" }}>
                        {totalScore >= 50 ||
                        scoreBasedOnCorrectAnswers >= 50 ? (
                          <>
                            <div className="d-flex align-items-center text-start">
                              <img src={imgHintTransparent} />
                              <p className="ms-2 mb-0 text-gold">
                                Namig vas bo stal 50 točk.
                              </p>
                            </div>
                            <Button
                              className="btn-sm mt-1 mb-0 w-100"
                              label="No, prav"
                              onClick={() => handleHintClick("confirm")}
                            />
                          </>
                        ) : (
                          <div>
                            <div className="d-flex align-items-center text-start">
                              <img src={imgHintTransparent} />
                              <p className="ms-2 mb-0 text-gold">
                                Ne morete uporabiti namiga, ker nimate dovolj
                                točk.
                              </p>
                            </div>
                            <Button
                              className="btn-sm mt-1 mb-0 w-100"
                              label="No, prav"
                              onClick={() => handleHintClick("close")}
                            />
                          </div>
                        )}
                      </div>
                    )}

                    {gamesData.id !== "game6" && (
                      <div
                        className={`position-absolute hint ${
                          isHintActivated && "disabled"
                        }`}
                        style={{ right: "10px", bottom: "10px" }}>
                        <img
                          onClick={() => handleHintClick("show")}
                          src={imgHint}
                        />
                      </div>
                    )}
                  </>
                  <div className="w-100 text-center mt-auto mb-4">
                    <button
                      type="submit"
                      className="btn btn-primary btn-confirm fw-bold text-uppercase"
                      disabled={answerClicked === null && "disabled"}>
                      Čisto zares? Ja!
                    </button>
                  </div>
                </motion.div>
              </AnimatePresence>
            </form>
          </>
        )}
      </div>
      <div className="d-none">
        <StatsGlobal
          correctAnswersNum={correctAnswersNum}
          timeLeft={timeLeft}
        />
      </div>
    </Wrapper>
  );
}

// Pick random number with no repeats
function randomNoRepeats(array) {
  var copy = array.slice(0);
  return function () {
    if (copy.length < 1) {
      copy = array.slice(0);
    }
    var index = Math.floor(Math.random() * copy.length);
    var item = copy[index];
    copy.splice(index, 1);
    return item;
  };
}

// Shuffle array
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export default Quiz;
