import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGamesQuery,
  useUpdateGameMutation,
  useUpdateTeamMutation,
} from "services/gameApi";
import Lottie from "lottie-react";
import animationCorrect from "utils/lottie/correct_green.json";
import animationCorrectRed from "utils/lottie/correct_red.json";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
//import { gamesData } from "../gamesData";

const GameBonusResult = ({ gamesData }) => {
  // Total user score

  const token = useSelector((state) => state.user.token);

  const score = parseInt(useSelector((state) => state.game.score));
  const correctAnswers = useSelector((state) => state.game.correctAnswers);
  const navigate = useNavigate();

  let correctAnswersNum = correctAnswers.filter((x) => x === true).length;

  const { data, isSuccess } = useGamesQuery({});
  const [updateTeam] = useUpdateTeamMutation();

  const updateTeamData = () => {
    const filteredTeamStatus = data.users
      .filter((i) => i.token === token)
      .map((obj) => {
        const settingsGame = obj.settings.games.map((game) => {
          if (game.id === gamesData.ident) {
            return {
              ...game,
              status: "completed",
              score: score,

              correct: correctAnswers,
              correctNum: correctAnswersNum,
            };
          }
          return game;
        });

        return {
          ...obj,
          score: obj.score + score,

          settings: { ...obj.settings, games: settingsGame },
        };

        //return obj;
      });

    updateTeam(filteredTeamStatus[0]);
  };

  const vprasanjaDvojina = () => {
    if (correctAnswersNum === 1) {
      return "vprašanje";
    }
    if (correctAnswersNum === 2) {
      return "vprašanji";
    }
    if (correctAnswersNum === 3) {
      return "vprašanja";
    }
    if (correctAnswersNum === 4) {
      return "vprašanja";
    }
    if (correctAnswersNum > 4) {
      return "vprašanj";
    }
  };

  useEffect(() => {
    if (isSuccess) {
      updateTeamData();
    }
  }, [isSuccess]);
  return (
    <Wrapper
      className={`${
        gamesData.type === "tapping"
          ? "bg-blue tapping-game"
          : "bg-green bonus-game"
      } p-3  shadow-wrapper w-100 text-center position-relative `}>
      <div className="bg-gradient-gold shadow-wrapper">
        <h2 className="display-6 fw-bold text-white text-uppercase p-3">
          {gamesData.headline}
        </h2>
      </div>
      {isSuccess && (
        <div
          className={`${
            gamesData.type === "tapping" ? "bg-babyblue" : "bg-green-medium"
          } d-flex flex-column text-white bg-opacity-50 h-100 shadow-wrapper mt-3`}>
          <div className="flex-grow-1 pt-5">
            <p className="fw-strong">
              {gamesData.type === "tapping" && <>Igra je končana!</>}

              {gamesData.type === "quizbonus" && (
                <> Zacingljal je 60-sekundni zvonec, igre je konec!</>
              )}
            </p>
            <h2 className="text-white">
              {gamesData.type === "tapping" && (
                <>
                  Pritiskali ste si
                  <br />
                  {score} točk.
                </>
              )}

              {gamesData.type === "quizbonus" && (
                <div className="px-5">
                  Pravilno ste rešili {correctAnswersNum} {vprasanjaDvojina()},
                  priigrali ste si dodatnih {score} točk.
                </div>
              )}
            </h2>
            <div className="w-50 mx-auto">
              {gamesData.type === "tapping" ? (
                <Lottie animationData={animationCorrectRed} loop={false} />
              ) : (
                <Lottie animationData={animationCorrect} loop={false} />
              )}
            </div>
          </div>
          <Button
            label="Naprej"
            onClick={() => navigate("/dashboard")}
            className="mt-auto w-50 mx-auto mb-5"
          />
        </div>
      )}
    </Wrapper>
  );
};

export default GameBonusResult;
