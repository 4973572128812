import i18next from "i18next";

const chapters = [1, 2, 3];
const questions = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];

const gamesDataQuizBonus = chapters.reduce((previous, current) => {
  return Object.assign(previous, {
    ["quizbonus" + current]: {
      id: `quizbonus`,
      ident: `quizbonus${current}`,
      type: "quizbonus",
      headline: "BONUS: Drži, ne drži",
      percentForSuccess: 0,
      numberOfQuestions: 20,
      introText: i18next.t(`B${current}IntroText`),
      failText: i18next.t(`B${current}FailText`),
      successText: i18next.t(`B${current}SuccessText`),
      triviaText: i18next.t(`B${current}TriviaText`),
      step: questions.map((a) => ({
        questionText: i18next.t(`B${current}Q${a}Text`),
        questionImg: i18next.t(`B${current}Q${a}Img`),
        answerIsImg: false,
        answers: [
          {
            answer: i18next.t(`B${current}Q${a}Answer1`),
            isCorrect: i18next.t(`B${current}Q${a}Answer1T`),
          },
          {
            answer: i18next.t(`B${current}Q${a}Answer2`),
            isCorrect: i18next.t(`B${current}Q${a}Answer2T`),
          },
        ],
      })),
    },
  });
}, {});

const gamesDataTapping = {
  tapping: {
    id: "tapping",
    ident: "tapping",
    type: "tapping",
    headline: "Hitri prstki",
    percentForSuccess: 60,
    numberOfQuestions: 10,
    introText: i18next.t("S1IntroText"),
    failText: i18next.t("S1FailText"),
    successText: i18next.t("S1SuccessText"),
    triviaText: i18next.t("S1TriviaText"),
    step: [
      {
        questionText: i18next.t("S1Q1Text"),
        questionImg: "https://escapebox.si/temp/bankaslovenije/question.jpg",
        answerIsImg: true,
        answers: [
          {
            answer: "https://escapebox.si/temp/bankaslovenije/answer.jpg",
            isCorrect: false,
          },
          {
            answer: "https://escapebox.si/temp/bankaslovenije/answer.jpg",
            isCorrect: false,
          },
          {
            answer: "https://escapebox.si/temp/bankaslovenije/answer.jpg",
            isCorrect: false,
          },
          {
            answer: "https://escapebox.si/temp/bankaslovenije/answer.jpg",
            isCorrect: true,
          },
        ],
      },
      {
        questionText: i18next.t("S1Question2Text"),
        questionImg: null,
        answerIsImg: true,
        answers: [
          {
            answer: "https://escapebox.si/temp/bankaslovenije/answer.jpg",
            isCorrect: false,
          },
          {
            answer: "https://escapebox.si/temp/bankaslovenije/answer.jpg",
            isCorrect: false,
          },
          {
            answer: "https://escapebox.si/temp/bankaslovenije/answer.jpg",
            isCorrect: false,
          },
          {
            answer: "https://escapebox.si/temp/bankaslovenije/answer.jpg",
            isCorrect: true,
          },
        ],
      },
      {
        questionText: i18next.t("S1Question3Text"),
        questionImg: "https://escapebox.si/temp/bankaslovenije/question.jpg",
        answerIsImg: false,
        answers: [
          { answer: i18next.t("S1Q3Answer1"), isCorrect: false },
          { answer: i18next.t("S1Q3Answer2"), isCorrect: false },
          { answer: i18next.t("S1Q3Answer3"), isCorrect: false },
          { answer: i18next.t("S1Q3Answer4"), isCorrect: true },
        ],
      },
      {
        questionText: i18next.t("S1Question4Text"),
        questionImg: "https://escapebox.si/temp/bankaslovenije/question.jpg",
        answerIsImg: true,
        answers: [
          {
            answer: "https://escapebox.si/temp/bankaslovenije/answer.jpg",
            isCorrect: false,
          },
          {
            answer: "https://escapebox.si/temp/bankaslovenije/answer.jpg",
            isCorrect: false,
          },
          {
            answer: "https://escapebox.si/temp/bankaslovenije/answer.jpg",
            isCorrect: false,
          },
          {
            answer: "https://escapebox.si/temp/bankaslovenije/answer.jpg",
            isCorrect: true,
          },
        ],
      },
      {
        questionText: i18next.t("S1Question5Text"),
        questionImg: null,
        answerIsImg: false,
        answers: [
          { answer: i18next.t("S1Q5Answer1"), isCorrect: false },
          { answer: i18next.t("S1Q5Answer2"), isCorrect: false },
          { answer: i18next.t("S1Q5Answer3"), isCorrect: false },
          { answer: i18next.t("S1Q5Answer4"), isCorrect: true },
        ],
      },
      {
        questionText: i18next.t("S1Question6Text"),
        questionImg: null,
        answerIsImg: false,
        answers: [
          { answer: i18next.t("S1Q6Answer1"), isCorrect: false },
          { answer: i18next.t("S1Q6Answer2"), isCorrect: false },
          { answer: i18next.t("S1Q6Answer3"), isCorrect: false },
          { answer: i18next.t("S1Q6Answer4"), isCorrect: true },
        ],
      },
    ],
  },
};

export const gamesData = { ...gamesDataQuizBonus, ...gamesDataTapping };
