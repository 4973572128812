import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgFigure from "assets/images/figure-banka-questions.svg";
import imgUsp1 from "assets/images/rules-usp1.svg";
import imgUsp2 from "assets/images/rules-usp2.svg";
import imgUsp3 from "assets/images/rules-usp3.svg";
import imgUsp4 from "assets/images/rules-usp4.svg";

import imgRules1 from "assets/images/rules-1.svg";
import imgRules2 from "assets/images/rules-2.svg";
import imgRules3 from "assets/images/rules-3.svg";

const Rules = ({ step, setStep }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const clickHandler = () => {
    navigate("/intro/teamname");
  };

  return (
    <Wrapper className="Rules text-center">
      <div className="flex-grow-1 pt-5">
        <h1 className="text-green">{t("RulesH")}</h1>
        <div className="row mt-5 px-5">
          <div className="col-3">
            <div className="w-100 mb-3">
              <img src={imgUsp1} />
            </div>
            <p className="small">{t("RulesUsp1")}</p>
          </div>
          <div className="col-3">
            <div className="w-100 mb-3">
              <img src={imgUsp2} />
            </div>
            <p className="small">{t("RulesUsp2")}</p>
          </div>
          <div className="col-3">
            <div className="w-100 mb-3">
              <img src={imgUsp3} />
            </div>
            <p className="small">{t("RulesUsp3")}</p>{" "}
          </div>
          <div className="col-3">
            <div className="w-100 mb-3">
              <img src={imgUsp4} />
            </div>
            <p className="small">{t("RulesUsp4")}</p>
          </div>
        </div>

        <div className="bg-gradient-gold p-3 mx-5 rounded-2 mt-3">
          <h2 className="text-white">Opa, kaj pa je to?</h2>
          <div className="row g-0 d-flex align-items-center mt-4">
            <div className="col-5">
              <img src={imgFigure} />
            </div>
            <div className="col-7">
              <div className="d-flex align-items-center text-white text-start">
                <div className="pe-4">
                  <img src={imgRules1} />
                </div>
                <p
                  className="mb-0 small"
                  dangerouslySetInnerHTML={{ __html: t("RulesBooost1") }}></p>
              </div>
              <div className="d-flex align-items-center text-white text-start mt-3">
                <div className="pe-4">
                  <img src={imgRules2} />
                </div>
                <p
                  className="mb-0 small"
                  dangerouslySetInnerHTML={{ __html: t("RulesBooost2") }}></p>
              </div>
              <div className="d-flex align-items-center text-white text-start mt-3">
                <div className="pe-4">
                  <img src={imgRules3} />
                </div>
                <p
                  className="mb-0 small"
                  dangerouslySetInnerHTML={{ __html: t("RulesBooost3") }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Button
        label="Vse jasno"
        showPrev="true"
        className="w-50 mx-auto mt-4 mb-3"
        onClick={clickHandler}
      />
    </Wrapper>
  );
};

export default Rules;
