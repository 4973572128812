import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentQuestion: 0,
  correctAnswers: [],
  score: 0,
  time: { quiz: 300000, tapping: 600, quizbonus: 600 },
  showIntro: true,
  showResult: false,
  showTrivia: false,

  completedGame: [
    {
      id: "skrivnostnerazlike",
      completed: false,
    },
    {
      id: "zacaranspomin",
      completed: false,
    },
    {
      id: "carobnimagneti",
      completed: false,
    },
  ],
};

export const resultsSlice = createSlice({
  name: "results",
  initialState,
  reducers: {
    completedGameReducer: (state, action) => {
      const game = state.completedGame.find((e) => e.id === action.payload);

      if (game) {
        game.completed = true;
      }
    },
    reset: () => initialState,
  },
});

export const { completedGameReducer, reset } = resultsSlice.actions;

export default resultsSlice.reducer;
