import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { useGamesQuery } from "services/gameApi";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import ConfettiAtlant from "utils/ConfettiAtlant";

import imgFigure from "assets/images/figure-banka.svg";
import imgQ1 from "assets/images/question-1.svg";
import imgQ2 from "assets/images/question-2.svg";
import imgQ3 from "assets/images/question-3.svg";
import imgQ4 from "assets/images/question-4.svg";

const FinalCode = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    setTimeout(() => {
      navigate(`/leaderboard`);
    }, 5000);
  }, []);

  return (
    <div className="main-container theme-gold shadow-wrapper">
      <Wrapper className="finalcode text-center position-relative">
        <ConfettiAtlant color="'#BFA675','84A76A'" />
        <div className="flex-grow-1 pt-7">
          <h1 className="text-green">Bravo!</h1>
          <div className="px-7 mt-6">
            <div className="lead">
              Uspešno ste rešili vseh 7 preizkusov! Dokazali ste se vredni
              Atlantovega zaupanja, zato je čas, da odkrijete njegovo skrivnost!
            </div>
          </div>
        </div>

        {/*         <Button
          label="Ogled rezultata"
          className="w-50 mx-auto mb-5"
          onClick={() => navigate("/leaderboard")}
        /> */}
      </Wrapper>
    </div>
  );
};

export default FinalCode;
