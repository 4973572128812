import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  correctAnswersTotal: [],
  name: "",
  banknote: "",
  totalscore: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    tokenReducer: (state, action) => {
      state.token = action.payload;
    },
    correctAnswersTotalReducer: (state, action) => {
      if (action.payload === "reset") {
        state.correctAnswersTotal = [];
      } else {
        state.correctAnswersTotal.push(action.payload);
      }
    },

    nameReducer: (state, action) => {
      state.name = action.payload;
    },

    banknoteReducer: (state, action) => {
      state.banknote = action.payload;
    },

    totalscoreReducer: (state, action) => {
      state.totalscore = action.payload;
    },

    reset: () => initialState,
  },
});

export const {
  tokenReducer,
  correctAnswersTotalReducer,
  nameReducer,
  banknoteReducer,
  totalscoreReducer,
  reset,
} = userSlice.actions;

export default userSlice.reducer;
