import { createElement } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showIntroReducer } from "store/gameSlice";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import { ReactComponent as ImgP0 } from "assets/images/preizkusi/game0-badge-dark.svg";

import imgFigure from "assets/images/figure-banka-questions2.svg";

const GameBanknoteIntro = ({ gamesData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClick = () => {
    dispatch(showIntroReducer(false));
  };

  return (
    <Wrapper
      className={`game banknote intro bg-gold-light shadow-wrapper w-100 position-relative `}>
      <div className="flex-grow-1 p-3">
        <div className="d-flex align-items-center">
          <div className="badge-top">
            <h3>{t("S0Headline")}</h3>

            <ImgP0 />
          </div>
          <div className="badge-top ms-5">
            <h2
              className="text-gold mb-0"
              dangerouslySetInnerHTML={{ __html: t("S0Intro1") }}
            />
          </div>
        </div>

        <div className="pt-5 px-3 text-start">
          <img className="float-end ms-1 mt-n8" src={imgFigure} />

          <div dangerouslySetInnerHTML={{ __html: t("S0Intro2") }} />
        </div>
      </div>
      <Button
        label="Naprej"
        className="w-50 mx-auto mb-5"
        onClick={handleClick}
      />
    </Wrapper>
  );
};

export default GameBanknoteIntro;
