import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { fadeInOut, blurInOut, scaleInOut } from "utils/animations";
import Lottie from "lottie-react";
import {
  showResultReducer,
  showTriviaReducer,
  showMilestoneReducer,
  scoreReducer,
  reset,
} from "store/gameSlice";
import {
  useGamesQuery,
  useUpdateGameMutation,
  useUpdateTeamMutation,
} from "services/gameApi";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgTime from "assets/images/icon-time.svg";
import imgScore from "assets/images/icon-score.svg";
import imgSuccess from "assets/images/icon-success.svg";
import imgFigureConfetti from "assets/images/figure-banka-confetti.svg";
import imgFigureSad from "assets/images/figure-banka-sad.svg";

import animationCorrect from "utils/lottie/correct_green.json";

const GameQuizResult = ({ gamesData }) => {
  const [totalScore, setTotalScore] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const score = parseInt(useSelector((state) => state.game.score));
  const time = useSelector((state) => state.game.time[gamesData.type]);
  const correctAnswers = useSelector((state) => state.game.correctAnswers);
  const token = useSelector((state) => state.user.token);
  const showMilestone = useSelector((state) => state.game.showMilestone);
  const correctAnswersTotal = useSelector(
    (state) => state.user.correctAnswersTotal
  );

  let totalQuestionsNum = gamesData.numberOfQuestions;
  let correctAnswersNum = correctAnswers.filter((x) => x === true).length;
  let successRate = Math.round((correctAnswersNum / totalQuestionsNum) * 100);

  const { data, error, isLoading, isSuccess, refetch } = useGamesQuery();
  const [updateGame] = useUpdateGameMutation();
  const [updateTeam] = useUpdateTeamMutation();

  const handleClickSuccess = () => {
    dispatch(showResultReducer(false));
    dispatch(showTriviaReducer(true));
  };
  const handleClickAgain = () => {
    dispatch(showResultReducer(false));
    setTimeout(() => {
      dispatch(reset());
    }, 100);
  };

  const isUnlocked = () => successRate >= 60;

  useEffect(() => {
    if (isUnlocked() && isSuccess) {
      console.log(data);
      updateGameData(gamesData.id);
      updateTeamData();

      const getScore = data.users.filter((i) => i.token === token);
      setTotalScore(getScore[0].score);

      // Show milestone raketni tek (prej kot v 2 min)
      if (time > 1800) {
        dispatch(scoreReducer("raketnitek"));
        dispatch(showMilestoneReducer("raketnitek"));
      }
    }
  }, []);

  const updateGameData = (game) => {
    const filteredGameStatus = data.stats.games.map((obj) => {
      if (obj.id === game) {
        return { ...obj, status: "open" };
      }

      return obj;
    });

    const newStatus = { ...data, stats: { games: filteredGameStatus } };

    console.log("newStatus", newStatus);

    updateGame(newStatus);
  };

  const updateTeamData = () => {
    const filteredTeamStatus = data.users
      .filter((i) => i.token === token)
      .map((obj) => {
        const settingsGame = obj.settings.games.map((game) => {
          if (game.id === gamesData.id) {
            return {
              ...game,
              status: "completed",
              score: score,
              time: 3000 - time,
              correct: correctAnswers,
              correctNum: correctAnswersNum,
            };
          }
          return game;
        });

        return {
          ...obj,
          score: obj.score + score,
          play_time: obj.play_time + time,
          settings: { ...obj.settings, games: settingsGame },
        };

        //return obj;
      });

    updateTeam(filteredTeamStatus[0]);
  };

  return (
    <Wrapper className={`quiz ${gamesData.id} w-100`}>
      <div className="position-relative text-center py-3 pt-6 flex-grow-1 d-flex flex-column bg-gold-light shadow-wrapper">
        <AnimatePresence>
          {showMilestone === "raketnitek" && (
            <motion.div
              className="lightbox milestone deset-v-vrsto raketni-tek rounded-2 d-flex align-items-center justify-content-center "
              variants={scaleInOut}
              initial="hidden"
              animate="visible"
              exit="exit">
              <motion.div
                variants={fadeInOut}
                initial="hidden"
                animate="visible"
                exit="exit">
                <div className="w-100 px-6 text-center">
                  <h3 className="text-green mb-5">Dosežek odklenjen!</h3>
                  <h3 className="text-white display-5 mb-5">
                    Preizkus opravljen prej kot
                    <br />v 3 minutah = Raketni tek
                  </h3>
                  <h2 className="text-white">Dodatnih 150 točk je vaših!</h2>
                  <Lottie
                    className="w-50 mx-auto"
                    animationData={animationCorrect}
                    loop={false}
                  />
                  <Button
                    label="Naprej"
                    className="bg-green w-100 px-5 py-4 "
                    onClick={() => dispatch(showMilestoneReducer(false))}
                  />
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <h2>
          <span className="opacity-50">
            {gamesData.headline} <br />
            {isUnlocked() ? "OPRAVLJEN!" : "NI OPRAVLJEN!"}
          </span>
        </h2>

        <div className="row my-4 d-flex align-items-center">
          <div className="col-6">
            {isUnlocked() ? (
              <img className="w-50" src={imgFigureConfetti} />
            ) : (
              <img className="w-50" src={imgFigureSad} />
            )}
          </div>

          <div className="col-6 text-start">
            <p className="mb-1 fw-bold">Pretečen čas:</p>
            <div className="d-flex align-items-center">
              <div className="icon">
                <img src={imgTime} className="me-2" />
              </div>
              <div className="stat">
                <h3 className="display-4 text-gold mb-0 fw-bold">
                  <span>
                    {("0" + Math.floor(((3000 - time) / 600) % 60)).slice(-2)}
                    m&nbsp;
                  </span>
                  <span>
                    {("0" + Math.floor(((3000 - time) / 10) % 60)).slice(-2)}s
                  </span>
                </h3>
              </div>
            </div>

            <p className="mb-1 mt-3 fw-bold">Zbrane točke v preizkusu: </p>
            <div className="d-flex align-items-center">
              <div className="icon">
                <img src={imgTime} className="me-2" />
              </div>
              <div className="stat">
                <h3 className="display-4 text-gold mb-0 fw-bold">{score}</h3>
              </div>
            </div>

            <p className="mb-1 mt-3 fw-bold">Zbrane točke v celi igri: </p>
            <div className="d-flex align-items-center">
              <div className="icon">
                <img src={imgTime} className="me-2" />
              </div>
              <div className="stat">
                <h3 className="display-4 text-gold mb-0 fw-bold">
                  {totalScore + score}
                </h3>
              </div>
            </div>

            <p className="mb-1 mt-3 fw-bold">Uspešnost odgovorov:</p>
            <div className="d-flex align-items-center">
              <div className="icon">
                <img src={imgTime} className="me-2" />
              </div>
              <div className="stat">
                <h3 className="display-4 text-gold mb-0 fw-bold">
                  {successRate} %
                </h3>
              </div>
            </div>
          </div>
        </div>

        {isUnlocked() ? (
          <div
            className="mt-4 px-6"
            dangerouslySetInnerHTML={{ __html: t("SResult1Success") }}
          />
        ) : (
          <p className="mt-4 px-6">{t("SResult1Fail")}</p>
        )}

        <div className="w-100 text-center mt-auto mb-3">
          {isUnlocked() ? (
            <Button
              onClick={handleClickSuccess}
              className="w-50 mx-auto mb-5"
              label="Naprej"
            />
          ) : (
            <Button
              onClick={handleClickAgain}
              className="w-50 mx-auto mb-5"
              label="Še enkrat"
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default GameQuizResult;
