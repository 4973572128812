import i18next from "i18next";

const chapters = [1, 2, 3, 4, 5, 6];
const questions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export const gamesData = chapters.reduce((previous, current) => {
  if (current === 6) {
    return Object.assign(previous, {
      ["game" + current]: {
        id: `game${current}`,
        type: "quiz",
        headline: i18next.t(`S${current}Headline`),
        percentForSuccess: 60,
        numberOfQuestions: 10,
        introText1: i18next.t(`S${current}IntroText1`),
        introText2: i18next.t(`S${current}IntroText2`),
        introTextUSP1: i18next.t(`S${current}IntroTextUSP1`),
        introTextUSP2: i18next.t(`S${current}IntroTextUSP2`),
        introTextUSP3: i18next.t(`S${current}IntroTextUSP3`),
        failText: i18next.t(`S${current}FailText`),
        successText: i18next.t(`S${current}SuccessText`),
        triviaText1: i18next.t(`S${current}TriviaText1`),
        triviaText2: i18next.t(`S${current}TriviaText2`),
        triviaText3: i18next.t(`S${current}TriviaText3`),
        triviaText4: i18next.t(`S${current}TriviaText4`),
        step: questions.map((a) => ({
          questionText: i18next.t(`S${current}Q${a}Text`),
          questionImg: i18next.t(`S${current}Q${a}Img`),
          answerIsImg: i18next.t(`S${current}Q${a}AnswerIsImg`),

          answers: [
            {
              answer: i18next.t(`S${current}Q${a}Answer1`),
              isCorrect: i18next.t(`S${current}Q${a}Answer1T`),
            },
            {
              answer: i18next.t(`S${current}Q${a}Answer2`),
              isCorrect: i18next.t(`S${current}Q${a}Answer2T`),
            },
          ],
        })),
      },
    });
  } else {
    return Object.assign(previous, {
      ["game" + current]: {
        id: `game${current}`,
        type: "quiz",
        headline: i18next.t(`S${current}Headline`),
        percentForSuccess: 60,
        numberOfQuestions: 10,
        introText1: i18next.t(`S${current}IntroText1`),
        introText2: i18next.t(`S${current}IntroText2`),
        introTextUSP1: i18next.t(`S${current}IntroTextUSP1`),
        introTextUSP2: i18next.t(`S${current}IntroTextUSP2`),
        introTextUSP3: i18next.t(`S${current}IntroTextUSP3`),
        failText: i18next.t(`S${current}FailText`),
        successText: i18next.t(`S${current}SuccessText`),
        triviaText1: i18next.t(`S${current}TriviaText1`),
        triviaText2: i18next.t(`S${current}TriviaText2`),
        triviaText3: i18next.t(`S${current}TriviaText3`),
        triviaText4: i18next.t(`S${current}TriviaText4`),
        step: questions.map((a) => ({
          questionText: i18next.t(`S${current}Q${a}Text`),
          questionImg: i18next.t(`S${current}Q${a}Img`),
          answerIsImg: i18next.t(`S${current}Q${a}AnswerIsImg`),

          answers: [
            {
              answer: i18next.t(`S${current}Q${a}Answer1`),
              isCorrect: false,
            },
            {
              answer: i18next.t(`S${current}Q${a}Answer2`),
              isCorrect: false,
            },
            {
              answer: i18next.t(`S${current}Q${a}Answer3`),
              isCorrect: false,
            },
            {
              answer: i18next.t(`S${current}Q${a}Answer4`),
              isCorrect: true,
            },
          ],
        })),
      },
    });
  }
}, {});
