import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useGamesQuery } from "services/gameApi";
import { fadeInOut } from "utils/animations";
import imgLogoHead from "assets/images/logo-header.svg";

const FixedTopBar = () => {
  const token = useSelector((state) => state.user.token);
  const { data, error, isLoading, isSuccess, refetch } = useGamesQuery();

  const NameDisplay = () => {
    const showName = data.users
      .filter((item) => item.token === token)
      .map((item) => {
        return (
          <div
            key={item.token}
            className="d-flex justify-content-between align-item-center px-3">
            <img src={imgLogoHead} />

            <div className="d-flex">
              <h5 className="d-flex align-items-center display-7 mb-0">
                <small className="fw-normal">Ime skupine:</small>
                <strong>{item.name}</strong>
              </h5>
              <img className="ms-3" src={item.settings.avatar} />
            </div>
          </div>
        );
      });

    if (showName.length === 0) {
      return (
        <div>
          <img src={imgLogoHead} />
        </div>
      );
    } else {
      return showName;
    }
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="fixed-top-bar d-flex align-items-center">
      <div className="w-100 text-center">{isSuccess && <NameDisplay />}</div>
    </motion.div>
  );
};

export default FixedTopBar;
