import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgFigure from "assets/images/figure-banka.svg";
import imgQ1 from "assets/images/question-1.svg";
import imgQ2 from "assets/images/question-2.svg";
import imgQ3 from "assets/images/question-3.svg";
import imgQ4 from "assets/images/question-4.svg";

const Story1 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Wrapper className="story1 w-100 text-center position-relative">
      <div className="flex-grow-1 pt-7">
        <h1 className="text-green">{t("Story1H")}</h1>
        <div className="narrow-container mt-4">
          <div className="lead">
            <p dangerouslySetInnerHTML={{ __html: t("Story1P") }} />
          </div>

          <h2 className="text-gold mt-5">{t("Story1P2")}</h2>
        </div>
      </div>
      <Button
        label="Naprej"
        className="my-5 w-50 mx-auto"
        onClick={() => navigate("/intro/story2")}
      />
      <img
        className="position-absolute"
        style={{ right: "39px", bottom: "50px" }}
        src={imgFigure}
      />
      <img
        className="position-absolute float float__d1"
        style={{ left: "50%", bottom: "25%" }}
        src={imgQ1}
      />
      <img
        className="position-absolute float float__d2"
        style={{ right: "10%", bottom: "40%" }}
        src={imgQ2}
      />
    </Wrapper>
  );
};

export default Story1;
