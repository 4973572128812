import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showIntroReducer } from "store/gameSlice";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import { ReactComponent as ImgP1 } from "assets/images/preizkusi/game1-badge.svg";
import { ReactComponent as ImgP2 } from "assets/images/preizkusi/game2-badge.svg";
import { ReactComponent as ImgP3 } from "assets/images/preizkusi/game3-badge.svg";
import { ReactComponent as ImgP4 } from "assets/images/preizkusi/game4-badge.svg";
import { ReactComponent as ImgP5 } from "assets/images/preizkusi/game5-badge.svg";
import { ReactComponent as ImgP6 } from "assets/images/preizkusi/game6-badge.svg";

import { ReactComponent as ImgP1BG } from "assets/images/preizkusi/game1-intro-badge-bg.svg";
import { ReactComponent as ImgP2BG } from "assets/images/preizkusi/game2-intro-badge-bg.svg";
import { ReactComponent as ImgP3BG } from "assets/images/preizkusi/game3-intro-badge-bg.svg";
import { ReactComponent as ImgP4BG } from "assets/images/preizkusi/game4-intro-badge-bg.svg";
import { ReactComponent as ImgP5BG } from "assets/images/preizkusi/game5-intro-badge-bg.svg";
import { ReactComponent as ImgP6BG } from "assets/images/preizkusi/game6-intro-badge-bg.svg";
import imgFigure from "assets/images/figure-banka.svg";
import imgHint from "assets/images/icon-hint.svg";

const GameQuizTrivia = ({ gamesData }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/dashboard");
  };

  return (
    <Wrapper
      className={`game ${gamesData.id} intro bg-gold-light shadow-wrapper w-100 position-relative `}>
      <div className="flex-grow-1 p-3">
        <div className="badge-top d-none">
          <h3>{gamesData.headline}</h3>
          {gamesData.id === "game1" && <ImgP1 />}
          {gamesData.id === "game2" && <ImgP2 />}
          {gamesData.id === "game3" && <ImgP3 />}
          {gamesData.id === "game4" && <ImgP4 />}
          {gamesData.id === "game5" && <ImgP5 />}
          {gamesData.id === "game6" && <ImgP6 />}
        </div>

        <div className="pt-5 px-4 text-center">
          <h2>
            Preizkus ste osvojili,
            <br />
            da ne boste pozabili:
          </h2>

          <div className="bg text-white p-5 rounded-2 mt-5">
            <div className="row g-0 d-flex align-items-center ">
              <div className="col-5">
                <img style={{ transform: "scaleX(-1)" }} src={imgFigure} />
              </div>
              <div className="col-7">
                <div className="d-flex align-items-center text-dark text-start">
                  <div className="pe-4">
                    <img src={imgHint} />
                  </div>
                  <p className="mb-0 small">{gamesData.triviaText1}</p>
                </div>
                <div className="d-flex align-items-center text-dark text-start mt-3">
                  <div className="pe-4">
                    <img src={imgHint} />
                  </div>
                  <p className="mb-0 small">{gamesData.triviaText2}</p>
                </div>
                <div className="d-flex align-items-center text-dark text-start mt-3">
                  <div className="pe-4">
                    <img src={imgHint} />
                  </div>
                  <p className="mb-0 small">{gamesData.triviaText3}</p>
                </div>
                <div className="d-flex align-items-center text-dark text-start mt-3">
                  <div className="pe-4">
                    <img src={imgHint} />
                  </div>
                  <p className="mb-0 small">{gamesData.triviaText4}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        label={`Naprej`}
        className={`w-50 mx-auto mb-5 `}
        onClick={handleClick}
      />

      {gamesData.id === "game1" && <ImgP1BG className="badge-corner" />}
      {gamesData.id === "game2" && <ImgP2BG className="badge-corner" />}
      {gamesData.id === "game3" && <ImgP3BG className="badge-corner" />}
      {gamesData.id === "game4" && <ImgP4BG className="badge-corner" />}
      {gamesData.id === "game5" && <ImgP5BG className="badge-corner" />}
      {gamesData.id === "game6" && <ImgP6BG className="badge-corner" />}
    </Wrapper>
  );
};

export default GameQuizTrivia;
