import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const gameApi = createApi({
  reducerPath: "gamesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://atlantovaskrivnost-dev.escapebox.si/api/public/",
  }),
  tagTypes: ["Game"],
  endpoints: (builder) => ({
    games: builder.query({
      query: () => "/game",
      providesTags: ["Game"],
    }),

    newGame: builder.mutation({
      query: (game) => ({
        url: "/game",
        method: "POST",
        body: game,
      }),
      invalidatesTags: ["Game"],
    }),

    updateGame: builder.mutation({
      query: (game) => ({
        url: "/game",
        method: "PUT",
        body: game,
      }),
      invalidatesTags: ["Game"],
    }),

    completeGame: builder.mutation({
      query: (game) => ({
        url: "/game/complete",
        method: "POST",
        body: game,
      }),
      invalidatesTags: ["Game"],
    }),

    newTeam: builder.mutation({
      query: (game) => ({
        url: "/user",
        method: "POST",
        body: game,
      }),
      invalidatesTags: ["Game"],
    }),

    updateTeam: builder.mutation({
      query: (game) => ({
        url: "/user",
        method: "PUT",
        body: game,
      }),
      invalidatesTags: ["Game"],
    }),
  }),
});

export const {
  useGamesQuery,
  useCompleteGameMutation,
  useNewGameMutation,
  useUpdateGameMutation,
  useNewTeamMutation,
  useUpdateTeamMutation,
} = gameApi;
