import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AnimatePresence } from "framer-motion";

// Import content
import { gamesData } from "./gamesData";

import GameBonusIntro from "./GameBonusIntro/GameBonusIntro";
import GameBonusResult from "./GameBonusResult/GameBonusResult";
import FastTapping from "components/FastTapping/FastTapping";
import Quiz from "components/Quiz/Quiz";

const GameBonus = () => {
  /*   const [showIntro, setShowIntro] = useState(true);
  const [showResult, setShowResult] = useState(false);
 */

  const showIntro = useSelector((state) => state.game.showIntro);
  const showResult = useSelector((state) => state.game.showResult);

  const [taps, setTaps] = useState(0);

  // Idenity game based on params
  const { gamebonus } = useParams();
  const gameId = gamebonus.replace(/-/g, "");

  return (
    <div className="main-container bonus">
      <AnimatePresence mode="wait">
        {showIntro ? (
          <GameBonusIntro key="intro" gamesData={gamesData[gameId]} />
        ) : showResult ? (
          <GameBonusResult
            key="result"
            gamesData={gamesData[gameId]}
            taps={taps}
          />
        ) : (
          <>
            {gameId === "tapping" && (
              <FastTapping
                key="game"
                gamesData={gamesData[gameId]}
                taps={taps}
                setTaps={setTaps}
              />
            )}

            {(gameId === "quizbonus1" ||
              gameId === "quizbonus2" ||
              gameId === "quizbonus3") && (
              <Quiz key="quiz" gamesData={gamesData[gameId]} />
            )}
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default GameBonus;
