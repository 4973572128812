import { createElement } from "react";
import { useDispatch } from "react-redux";
import { showIntroReducer } from "store/gameSlice";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import { ReactComponent as ImgP1 } from "assets/images/preizkusi/game1-badge-dark.svg";
import { ReactComponent as ImgP2 } from "assets/images/preizkusi/game2-badge-dark.svg";
import { ReactComponent as ImgP3 } from "assets/images/preizkusi/game3-badge-dark.svg";
import { ReactComponent as ImgP4 } from "assets/images/preizkusi/game4-badge-dark.svg";
import { ReactComponent as ImgP5 } from "assets/images/preizkusi/game5-badge-dark.svg";
import { ReactComponent as ImgP6 } from "assets/images/preizkusi/game6-badge-dark.svg";

import { ReactComponent as ImgP1BG } from "assets/images/preizkusi/game1-intro-badge-bg.svg";
import { ReactComponent as ImgP2BG } from "assets/images/preizkusi/game2-intro-badge-bg.svg";
import { ReactComponent as ImgP3BG } from "assets/images/preizkusi/game3-intro-badge-bg.svg";
import { ReactComponent as ImgP4BG } from "assets/images/preizkusi/game4-intro-badge-bg.svg";
import { ReactComponent as ImgP5BG } from "assets/images/preizkusi/game5-intro-badge-bg.svg";
import { ReactComponent as ImgP6BG } from "assets/images/preizkusi/game6-intro-badge-bg.svg";
import imgFigure from "assets/images/figure-banka-questions2.svg";

const GameQuizIntro = ({ gamesData }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(showIntroReducer(false));
  };

  return (
    <Wrapper
      className={`game ${gamesData.id} intro bg-gold-light shadow-wrapper w-100 position-relative `}>
      <div className="flex-grow-1 p-3">
        <div className="badge-top">
          <h3>{gamesData.headline}</h3>

          {gamesData.id === "game1" && <ImgP1 />}
          {gamesData.id === "game2" && <ImgP2 />}
          {gamesData.id === "game3" && <ImgP3 />}
          {gamesData.id === "game4" && <ImgP4 />}
          {gamesData.id === "game5" && <ImgP5 />}
          {gamesData.id === "game6" && <ImgP6 />}
        </div>

        <div className="pt-5 px-3 text-start">
          <p>
            <img className="float-end ms-1 mt-n8" src={imgFigure} />
            {gamesData.introText1}
          </p>

          <h3 className="text-gold mt-5">Kje se nahaja preizkus?</h3>
          <p>{gamesData.introText2}</p>
          <h3 className="text-gold mt-5">Kaj boste spoznali?</h3>
          <div className="row w-100 mt-4">
            <div className="col-4">
              <div className="circle text-center">
                <div className="circle-icon mx-auto d-flex align-items-center justify-content-center">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/images/${gamesData.id}-intro-usp1.svg`
                    }
                  />
                </div>
                <p className="mt-2">{gamesData.introTextUSP1}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="circle text-center">
                <div className="circle-icon mx-auto d-flex align-items-center justify-content-center">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/images/${gamesData.id}-intro-usp2.svg`
                    }
                  />
                </div>
                <p className="mt-2">{gamesData.introTextUSP2}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="circle text-center">
                <div className="circle-icon mx-auto d-flex align-items-center justify-content-center">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/images/${gamesData.id}-intro-usp3.svg`
                    }
                  />
                </div>
                <p className="mt-2">{gamesData.introTextUSP3}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        label="START"
        className="w-50 mx-auto mb-5"
        onClick={handleClick}
      />

      {gamesData.id === "game1" && <ImgP1BG className="badge-corner" />}
      {gamesData.id === "game2" && <ImgP2BG className="badge-corner" />}
      {gamesData.id === "game3" && <ImgP3BG className="badge-corner" />}
      {gamesData.id === "game4" && <ImgP4BG className="badge-corner" />}
      {gamesData.id === "game5" && <ImgP5BG className="badge-corner" />}
      {gamesData.id === "game6" && <ImgP6BG className="badge-corner" />}
    </Wrapper>
  );
};

export default GameQuizIntro;
