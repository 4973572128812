import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Timer } from "../Stopwatch/Timer";
import AnimatedNumber from "animated-number-react";

import imgTime from "assets/images/icon-time-transparent.svg";
import imgScore from "assets/images/icon-score-transparent.svg";

const CurrentGameStatus = ({
  gamesData,
  successRate,
  scoreBasedOnCorrectAnswers,
  timeLeft,
  setTimeLeft,
  totalScore,
  isHintActivated,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [removeHint, setRemoveHint] = useState(false);
  const score = totalScore + scoreBasedOnCorrectAnswers;
  const formatValue = (value) => value.toFixed(0);

  const begin = (value) => {
    setIsAnimating(true);
  };
  const complete = (value) => {
    setIsAnimating(false);
  };

  useEffect(() => {
    if (isHintActivated) {
      setRemoveHint(true);
      setTimeout(() => {
        setRemoveHint(false);
      }, 2000);
    }
  }, [isHintActivated]);

  return (
    <div className="current-game-status mb-4">
      <div className="d-flex justify-content-between bd-highlight mb-3">
        <div
          className={`item ${gamesData.id}-bg-light p-2 d-flex justify-content-center shadow-wrapper rounded-2 text-white fw-bold px-4 bd-highlight`}>
          <img src={imgTime} className="me-2" />
          <span>
            <Timer timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
          </span>
        </div>
        {!gamesData.id === "tapping" && (
          <div className="p-2 bd-highlight">
            <h4 className="text-gold mb-0">{gamesData.headline}</h4>
          </div>
        )}

        <div
          className={`item ${
            gamesData.id
          }-bg-light p-2 d-flex justify-content-center rounded-2 text-white px-4 bd-highlight ${
            isAnimating && "isanimating"
          } ${isHintActivated && removeHint && "hint-activated"}`}
          id="score">
          <img src={imgScore} className="me-2" />
          <span>
            {gamesData.id === "tapping" ? (
              scoreBasedOnCorrectAnswers
            ) : (
              <AnimatedNumber
                value={score}
                formatValue={formatValue}
                begin={begin}
                complete={complete}
                duration={1500}
              />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CurrentGameStatus;
