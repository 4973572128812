import { motion } from "framer-motion";
import { fadeInOut } from "utils/animations";

import imgLogoBs from "assets/images/logo-bs.svg";
import imgLogoEb from "assets/images/logo-eb.svg";

const FixedBottomBar = () => {
  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="fixed-bottom-bar">
      <div className="d-flex align-items-center p-3">
        <div className="me-auto ">
          <img className="logo-banka" src={imgLogoBs} />
        </div>
        <div>
          <img style={{ height: "40px" }} src={imgLogoEb} />
        </div>
      </div>
    </motion.div>
  );
};

export default FixedBottomBar;
