import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

import Story1 from "./Story/Story1";
import Story2 from "./Story/Story2";
import Story3 from "./Story/Story3";
import Rules from "./Rules/Rules";
import TeamName from "views/Intro/TeamName/TeamName";

const Intro = ({ location }) => {
  const { intro } = useParams();

  return (
    <div className="main-container intro theme-gold">
      {intro === "story1" && <Story1 />}
      {intro === "story2" && <Story2 />}
      {intro === "story3" && <Story3 />}
      {intro === "rules" && <Rules />}
      {intro === "teamname" && <TeamName />}
    </div>
  );
};

export default Intro;
