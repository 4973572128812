import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showIntroReducer } from "store/gameSlice";
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import ConfettiAtlant from "utils/ConfettiAtlant";

import imgIntroTapping from "assets/images/bonus-tapping-intro.png";
import imgIntroQuiz from "assets/images/bonus-quiz-intro.png";

const GameBonusIntro = ({ gamesData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const clickHandler = () => {
    dispatch(showIntroReducer(false));
  };

  return (
    <Wrapper
      className={`${
        gamesData.type === "tapping" ? "bg-babyblue" : "bg-green-medium"
      }  shadow-wrapper w-100 text-center position-relative`}>
      <ConfettiAtlant color={"#E6DECE"} />
      <div className="flex-grow-1 pt-5 px-6 text-white ">
        {gamesData.type === "quizbonus" && (
          <>
            <img className="w-75" src={imgIntroQuiz} />

            <h2 className="text-white fw-bold mb-4">Presenečenje!</h2>
            <div dangerouslySetInnerHTML={{ __html: t("B1IntroP") }} />
          </>
        )}

        {gamesData.type === "tapping" && (
          <>
            <img className="w-75" src={imgIntroTapping} />

            <h2 className="text-white fw-bold mb-4">Presenečenje!</h2>
            <div dangerouslySetInnerHTML={{ __html: t("B2IntroP") }} />
          </>
        )}
      </div>

      <Button
        label={t("Mamoto")}
        className="w-50 mx-auto mb-5 bg-gold"
        onClick={clickHandler}
      />
    </Wrapper>
  );
};

export default GameBonusIntro;
