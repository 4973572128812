import { useTranslation } from "react-i18next";

const QuizQuestion = ({ data, gamesData }) => {
  const { t } = useTranslation();

  return (
    data.questionText !== null && (
      <div className="question-section mb-4">
        <div className="description">
          <p dangerouslySetInnerHTML={{ __html: t(data.questionText) }} />
          {data.questionImg !== null && !data.questionImg.includes("mp4") && (
            <img
              style={{ height: "200px", width: "auto" }}
              src={
                process.env.PUBLIC_URL +
                "/images/quiz/" +
                gamesData.id +
                "/" +
                data.questionImg
              }
            />
          )}
        </div>
      </div>
    )
  );
};

export default QuizQuestion;
