import Confetti from "react-confetti";
import useWindowDimensions from "utils/useWindowDimensions";
import imgCoin from "assets/images/coin.png";

const ConfettiAtlant = ({ color }) => {
  const { height, width } = useWindowDimensions();

  return (
    <Confetti
      width={width}
      height={height}
      colors={["#EE968D", "#026b4e", "#85c1c8", "#4a4f54", "#a48242"]}
      numberOfPieces={400}
      friction={0.99}
      gravity={0.1}
      tweenDuration={1500}
      recycle={false}
      opacity={1}
    />
  );
};

export default ConfettiAtlant;
