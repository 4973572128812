import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { reset } from "store/gameSlice";
// Import content
import { gamesData } from "./gamesData";

// Import quiz
import Quiz from "components/Quiz/Quiz";
import GameQuizIntro from "./GameQuizIntro/GameQuizIntro";
import GameQuizResult from "./GameQuizResult/GameQuizResult";
import GameQuizTrivia from "./GameQuizTrivia/GameQuizTrivia";

const GameQuiz = () => {
  const showIntro = useSelector((state) => state.game.showIntro);
  const showResult = useSelector((state) => state.game.showResult);
  const showTrivia = useSelector((state) => state.game.showTrivia);

  const dispatch = useDispatch();

  // Idenity game based on params
  const { game } = useParams();
  const gameId = game.replace(/-/g, "");

  return (
    <div className="main-container">
      <AnimatePresence mode="wait">
        {showIntro ? (
          <GameQuizIntro key="intro" gamesData={gamesData[gameId]} />
        ) : showResult ? (
          <GameQuizResult key="result" gamesData={gamesData[gameId]} />
        ) : showTrivia ? (
          <GameQuizTrivia key="trivia" gamesData={gamesData[gameId]} />
        ) : (
          <>
            <Quiz key="quiz" gamesData={gamesData[gameId]} />
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default GameQuiz;
