import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { showResultReducer, showTriviaReducer, reset } from "store/gameSlice";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgTime from "assets/images/icon-time.svg";
import imgScore from "assets/images/icon-score.svg";
import imgSuccess from "assets/images/icon-success.svg";
import imgFigureConfetti from "assets/images/figure-banka-confetti.svg";
import imgFigureSad from "assets/images/figure-banka-sad.svg";

const GameBanknoteResult = ({ noteItems }) => {
  const dispatch = useDispatch();
  const score = useSelector((state) => state.game.score);
  const navigate = useNavigate();
  const { t } = useTranslation();
  var countEmpty = 0;
  for (var key in noteItems) {
    if (noteItems[key] === "") {
      countEmpty++;
    }
  }

  const isSuccess = countEmpty < 5;

  const handleClickSuccess = () => {
    navigate(`/dashboard`);
  };
  const handleClickAgain = () => {
    dispatch(showResultReducer(false));
  };

  return (
    <Wrapper
      className={` bg-gold-light w-100 p-3 shadow-wrapper w-100 banknote text-center`}>
      <div className="bg-gradient-gold shadow-wrapper">
        <h2 className="display-6 fw-bold text-white text-uppercase p-3">
          {t("S0Headline")}
        </h2>
      </div>
      {isSuccess ? (
        <>
          <h2 className="mt-4 mb-5 text-gold">Čestitke!</h2>
          <div className="w-100 text-center">
            <img className="w-25 mb-5" src={imgFigureConfetti} />
          </div>

          <div dangerouslySetInnerHTML={{ __html: t("S0Results1") }} />

          <Button
            onClick={handleClickSuccess}
            className="w-50 mx-auto mt-4"
            label="Naprej"
          />
        </>
      ) : (
        <>
          <h2 className="mt-4 mb-5 text-gold">Oh, ne, ne bo šlo! </h2>
          <div className="w-100 text-center">
            <img className="w-25 mb-5" src={imgFigureSad} />
          </div>

          <div dangerouslySetInnerHTML={{ __html: t("S0Results2") }} />
          <Button
            onClick={handleClickAgain}
            className="w-50 mx-auto mt-4"
            label="Ponovite vajo"
          />
        </>
      )}
    </Wrapper>
  );
};

export default GameBanknoteResult;
