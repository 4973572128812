import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgFigure from "assets/images/figure-banka.svg";
import imgQ1 from "assets/images/question-1.svg";
import imgQ2 from "assets/images/question-2.svg";
import imgQ3 from "assets/images/question-3.svg";
import imgQ4 from "assets/images/question-4.svg";

const Story2 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Wrapper className="story2 text-center position-relative">
      <div className="flex-grow-1 pt-7">
        <div className="narrow-container mt-5">
          <div className="lead">
            <p dangerouslySetInnerHTML={{ __html: t("Story2P") }} />
          </div>
        </div>
      </div>
      <Button
        label="Naprej"
        showPrev="true"
        className="w-50 mx-auto mb-3"
        onClick={() => navigate("/intro/story3")}
      />
      <img
        className="position-absolute"
        style={{ left: "39px", bottom: "20px", transform: "scaleX(-1)" }}
        src={imgFigure}
      />
      <img
        className="position-absolute float float__d1"
        style={{ left: "10%", top: "2%" }}
        src={imgQ2}
      />
      <img
        className="position-absolute float float__d2"
        style={{ right: "10%", bottom: "40%" }}
        src={imgQ1}
      />

      <img
        className="position-absolute float float__d3"
        style={{ right: "55%", bottom: "20%" }}
        src={imgQ4}
      />
    </Wrapper>
  );
};

export default Story2;
