import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { useGamesQuery } from "services/gameApi";
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgHeadline from "assets/images/headline-lestvica.svg";
import imgQ1 from "assets/images/question-1.svg";
import imgQ2 from "assets/images/question-2.svg";
import imgQ3 from "assets/images/question-3.svg";
import imgQ4 from "assets/images/question-4.svg";
import imgScore from "assets/images/icon-score.svg";

const Leaderboard = () => {
  const [showNextButton, setShowNextButton] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, error, isLoading, isSuccess, refetch } = useGamesQuery({});

  const clickHandler = () => {
    navigate(`/finalcodereveal`);
  };

  const LeaderboardGraph = () => {
    var playDataSorted = data.users.slice();

    const playerData = playDataSorted
      .sort((a, b) => {
        if (allAreTrue(a.settings.games)) {
          return parseInt(b.score) - parseInt(a.score);
        } else {
          return parseInt(b.score);
        }
      })
      .map((obj, index) => {
        const gameCompleted = allAreTrue(obj.settings.games);

        return (
          <div
            key={obj.id}
            className={`item d-flex align-items-center justify-content-between text-start mt-3 px-2 py-1 ${
              !gameCompleted && "notcompleted"
            }`}>
            {gameCompleted ? (
              <>
                <div className="left d-flex  align-items-center">
                  <img src={obj.settings.avatar} />
                  <h3 className="text-gold mb-0 ms-3">{obj.name}</h3>
                </div>

                <div className="right">
                  <h3 className="text-green-dark d-flex  align-items-center fw-bold mb-0">
                    <img className="me-2" src={imgScore} />
                    {obj.score}
                  </h3>
                </div>
              </>
            ) : (
              <>
                <div className="left d-flex  align-items-center">
                  <img src={obj.settings.avatar} />
                  <h3 className="text-gold mb-0 ms-3">{obj.name}</h3>
                </div>
                <div className="center position-absolute">
                  <svg
                    className="loader"
                    version="1.1"
                    id="L9"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    enableBackground="new 0 0 0 0"
                    xmlSpace="preserve">
                    <path
                      fill="#EE968D"
                      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite"
                      />
                    </path>
                  </svg>
                  <span className="text-orange small fw-bold">
                    Ekipa še ni zaključila igre
                  </span>
                </div>
                <div className="right">
                  <h3 className="text-green-dark d-flex  align-items-center fw-bold mb-0">
                    <img className="me-2" src={imgScore} />
                    {obj.score}
                  </h3>
                </div>
              </>
            )}
          </div>
        );
      });

    return <div>{playerData}</div>;
  };

  useEffect(() => {
    if (isSuccess) {
      const test = data.users.filter((item) => allAreTrue(item.settings.games));

      if (test.length == data.number_of_players) {
        setShowNextButton(true);
      }
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="main-container theme-gold shadow-wrapper ">
      <Wrapper className="leaderboard text-center position-relative w-100">
        <div className="flex-grow-1 pt-5">
          <h1 className="text-green">
            <img className="w-100" src={imgHeadline} />
          </h1>
          <div className="px-5 mt-5">
            <div className="lead mb-5">
              <p>
                Zlata palica v muzeju, povej, <br />
                katera ekipa je bila najboljša v preizkušnji tej?
              </p>
              <p style={{ fontSize: "0.9rem" }}>
                P. S. Počakati morate, da vse ekipe končajo igro in se uvrstijo
                na lestvico preden lahko končate igro in odklenete skrivnostni
                sef. A pozor! Če ste najhitrejši, še ne pomeni, da ste
                najboljši!
              </p>
            </div>

            {isSuccess && <LeaderboardGraph />}
          </div>
        </div>

        {showNextButton && (
          <Button
            onClick={clickHandler}
            label="ODKLENITE SEF"
            className="mb-5 w-50 mx-auto "
          />
        )}
      </Wrapper>
    </div>
  );
};

function allAreTrue(arr) {
  return arr.every((element) => element.status === "completed");
}

function order(a, b) {
  return a < b ? -1 : a > b ? 1 : 0;
}

export default Leaderboard;
