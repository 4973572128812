import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgLogoBs from "assets/images/logo-bs.svg";
import imgLogoEb from "assets/images/logo-eb-svg.svg";
import imgHero from "assets/images/hero-image.svg";

const Logo = ({ step, setStep }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToStory = () => {
    setTimeout(() => {
      navigate("/intro/story1");
    }, 1000);
  };

  const logoAnimation = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delay: 0.5,
        duration: 2,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <div className="start-container intro theme-gold">
      <Wrapper className="start w-100 text-center">
        <div className="flex-grow-1">
          <motion.div
            className="h-100 position-relative d-flex align-items-center justify-content-center"
            variants={logoAnimation}
            initial="hidden"
            animate="show"
            onAnimationComplete={() => goToStory()}>
            <img className="w-50 mb-4" src={imgLogoEb} />
            <p className="lead position-absolute" style={{ bottom: "10px" }}>
              Avtorica ilustracije lika: Lea Vučko
            </p>
          </motion.div>
        </div>
      </Wrapper>
    </div>
  );
};

export default Logo;
