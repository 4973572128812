import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { Calculator } from "react-mac-calculator";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { useGamesQuery } from "services/gameApi";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgFigure from "assets/images/figure-banka.svg";
import imgQ1 from "assets/images/question-1.svg";
import imgQ2 from "assets/images/question-2.svg";
import imgQ3 from "assets/images/question-3.svg";
import imgQ4 from "assets/images/question-4.svg";
import imgCalc from "assets/images/icon-calculator.svg";

const FinalCodeReveal = () => {
  // Show calc
  const [showCalc, setShowCalc] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = useSelector((state) => state.user.token);

  const { data, error, isLoading, isSuccess, refetch } = useGamesQuery();

  const renderCode = (index, spanValues) => {
    return (
      <div key={index}>
        {spanValues.map((value) => (
          <span key={value}>{value}</span>
        ))}
      </div>
    );
  };

  const CodeDisplay = () => {
    if (isLoading) {
      return "LOADING";
    }

    if (isSuccess) {
      let playersNum = data.number_of_players;

      const codesSorted = data.users.slice();
      const filterCode = codesSorted
        .sort((a, b) => {
          return parseInt(b.id) - parseInt(a.id);
        })
        .map((obj, index) => {
          if (obj.token === token) {
            const playerConfigs = {
              10: [
                [3, 7, 3, 0],
                [1, 8, 6, 0],
                [0, 5, 6, 0],
                [0, 8, 3, 0],
                [0, 2, 8, 0],
                [0, 1, 2, 0],
                [0, 1, 8, 0],
                [0, 1, 5, 0],
                [0, 2, 2, 0],
                [0, 3, 3, 0],
              ],
              9: [
                [1, 0, 5, 0],
                [2, 4, 0, 0],
                [2, 2, 3, 0],
                [1, 0, 9, 0],
                [0, 4, 7, 0],
                [0, 2, 1, 0],
                [0, 1, 3, 0],
                [0, 5, 1, 0],
                [0, 1, 7, 0],
              ],
              8: [
                [0, 1, 2, 0],
                [0, 8, 3, 0],
                [1, 7, 1, 0],
                [4, 3, 3, 0],
                [0, 5, 6, 0],
                [0, 2, 1, 0],
                [0, 1, 6, 0],
                [0, 3, 4, 0],
              ],
              7: [
                [2, 8, 7, 0],
                [1, 2, 9, 0],
                [0, 7, 6, 0],
                [0, 2, 4, 0],
                [2, 4, 1, 0],
                [0, 1, 1, 0],
                [0, 5, 8, 0],
              ],
              6: [
                [1, 3, 4, 0],
                [1, 5, 4, 0],
                [1, 2, 0, 0],
                [1, 3, 3, 0],
                [1, 3, 8, 0],
                [1, 4, 7, 0],
              ],

              5: [
                [0, 9, 9, 0],
                [2, 1, 4, 0],
                [1, 2, 6, 0],
                [1, 7, 7, 0],
                [2, 1, 0, 0],
              ],
              4: [
                [1, 1, 2, 0],
                [2, 1, 4, 0],
                [3, 8, 5, 0],
                [1, 1, 5, 0],
              ],
              3: [
                [2, 3, 2, 0],
                [1, 8, 8, 0],
                [4, 0, 6, 0],
              ],
              2: [
                [3, 0, 5, 0],
                [5, 2, 1, 0],
              ],
              1: [[8, 2, 6, 0]],
            };

            const spanValues = playerConfigs[playersNum]?.[index];
            if (spanValues) {
              return renderCode(index, spanValues);
            }
          }
        });

      return filterCode;
    }
  };

  const handleCalcClick = (action) => {
    if (action === "show") {
      setShowCalc(true);
    }

    if (action === "close") {
      setShowCalc(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      navigate(`/`);
    }, 1200000);
  }, []);

  return (
    <div className="main-container theme-gold shadow-wrapper">
      <Wrapper className="finalcode text-center position-relative">
        <div className="flex-grow-1 pt-7">
          <h1 className="text-green">
            Odklenite
            <br />
            skrivnostni sef
          </h1>
          <div className="px-7 mt-6">
            <div className="lead">
              <p dangerouslySetInnerHTML={{ __html: t("CodeP") }} />
            </div>

            <div className="code bg-gold shadow-wrapper px-3 py-5 mt-5">
              <h2 className="text-white display-5 text-center mb-3">
                Geslo za ključavnico
              </h2>
              <div className="codenum">
                <CodeDisplay />
              </div>
            </div>
          </div>
        </div>

        <img
          className="position-absolute calc"
          style={{ left: "15px", bottom: "15px", width: "80px" }}
          onClick={() =>
            showCalc ? handleCalcClick("close") : handleCalcClick("show")
          }
          src={imgCalc}
        />

        <>
          {showCalc && (
            <div className="calculator-wrapper">
              <svg
                fill="#000000"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 24 24"
                xmlSpace="preserve">
                <path d="M5.6,4.2L4.2,5.6l6.4,6.4l-6.4,6.4l1.4,1.4l6.4-6.4l6.4,6.4l1.4-1.4L13.4,12l6.4-6.4l-1.4-1.4L12,10.6L5.6,4.2z" />
                <rect class="st0" fill="none" width="24" height="24" />
              </svg>

              <Calculator />
            </div>
          )}
        </>
        <img
          className="position-absolute float float__d1"
          style={{ left: "80%", top: "5%" }}
          src={imgQ1}
        />
        <img
          className="position-absolute float float__d2"
          style={{ left: "7%", top: "5%" }}
          src={imgQ2}
        />

        <img
          className="position-absolute float float__d3"
          style={{ left: "85%", top: "15%" }}
          src={imgQ4}
        />
      </Wrapper>
    </div>
  );
};

export default FinalCodeReveal;
