import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { tokenReducer } from "store/userSlice";
import { reset } from "store/userSlice";

import Keyboard from "react-simple-keyboard";

import { useNewTeamMutation, useGamesQuery } from "services/gameApi";

import "react-simple-keyboard/build/css/index.css";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgQ1 from "assets/images/question-1.svg";
import imgQ2 from "assets/images/question-2.svg";
import imgQ3 from "assets/images/question-3.svg";
import imgQ4 from "assets/images/question-4.svg";

const TeamName = () => {
  const [input, setInput] = useState("");
  const [layout, setLayout] = useState("default");
  const [isGameFilled, setIsGameFilled] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const keyboard = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, error, isLoading, isSuccess, refetch } = useGamesQuery();
  const [newTeam] = useNewTeamMutation();

  const onChange = (input) => {
    setInput(input);
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onChangeInput = (event) => {
    const input = event.target.value;
    setInput(input);
    keyboard.current.setInput(input);
  };

  const handleNewTeam = () => {
    const team = {
      name: input,
      token: genRandonString(12),
      settings: {
        games: [
          {
            id: "game1",
            status: "open",
            score: null,
            time: null,
            correct: [],
            correctNum: 0,
          },
          {
            id: "game2",
            status: "open",
            score: null,
            time: null,
            correct: [],
            correctNum: 0,
          },
          {
            id: "game3",
            status: "open",
            score: null,
            time: null,
            correct: [],
            correctNum: 0,
          },
          {
            id: "game4",
            status: "open",
            score: null,
            time: null,
            correct: [],
            correctNum: 0,
          },
          {
            id: "game5",
            status: "open",
            score: null,
            time: null,
            correct: [],
            correctNum: 0,
          },
          {
            id: "game6",
            status: "open",
            score: null,
            time: null,
            correct: [],
            correctNum: 0,
          },
          {
            id: "quizbonus1",
            status: "open",
            score: null,
            time: null,
            correct: [],
            correctNum: 0,
          },
          {
            id: "quizbonus2",
            status: "open",
            score: null,
            time: null,
            correct: [],
            correctNum: 0,
          },
          {
            id: "quizbonus3",
            status: "completed",
            score: null,
            time: null,
            correct: [],
            correctNum: 0,
          },
          {
            id: "tapping",
            status: "open",
            score: null,
            time: null,
            correct: [],
            correctNum: 0,
          },
        ],
      },
    };
    console.log(data.users);
    const gameFilled = data.users.length >= data.number_of_players;
    setIsGameFilled(data.users.length >= data.number_of_players);

    if (input !== "" && !gameFilled && !disableSubmit) {
      navigate(`/banknote`);
      dispatch(tokenReducer(team.token));
      newTeam(team);
      setDisableSubmit(true);
    }
  };

  useEffect(() => {
    dispatch(reset());
  }, []);

  return (
    <Wrapper className="teamname position-relative w-100 text-center">
      <div className="flex-grow-1 pt-7">
        <h1 className="text-green">
          Če bi bili bogovi, <br />
          bi se imenovali ...
        </h1>

        <div className="user-enter bg-gold bg-opacity-25 p-3 mx-4 mt-6">
          <div className="keyboard px-3 ">
            <p className="text-white">Pred začetkom igre izberite svoje ime.</p>
            <input
              value={input}
              className="form-control mb-4 text-center"
              placeholder={"Ime ekipe"}
              onChange={onChangeInput}
            />
            <Keyboard
              keyboardRef={(r) => (keyboard.current = r)}
              layoutName={layout}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </div>
        </div>
      </div>
      {isGameFilled && (
        <h3 className="text-danger">
          Preseženo je maksimalno število prijavljenih ekip.
        </h3>
      )}
      <Button
        onClick={handleNewTeam}
        disabled={disableSubmit}
        id="confirm"
        showPrev="true"
        className="w-50 mx-auto mb-3"
        label="Naprej"
      />

      <img
        className="position-absolute float float__d1"
        style={{ left: "80%", top: "5%" }}
        src={imgQ1}
      />
      <img
        className="position-absolute float float__d2"
        style={{ left: "7%", top: "5%" }}
        src={imgQ2}
      />

      <img
        className="position-absolute float float__d3"
        style={{ left: "85%", top: "15%" }}
        src={imgQ4}
      />
    </Wrapper>
  );
};

function genRandonString(length) {
  var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var charLength = chars.length;
  var result = "";
  for (var i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
}
export default TeamName;
