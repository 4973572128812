import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { tokenReducer } from "store/userSlice";
import { useGamesQuery } from "services/gameApi";
import { useCompleteGameMutation, useNewGameMutation } from "services/gameApi";

const Admin = () => {
  const [title, setTitle] = useState("");
  const [playersNum, setPlayersNum] = useState("");

  const { data, error, isLoading, isSuccess } = useGamesQuery({});
  const [isCreating, setIsCreating] = useState(false);
  const [completeGame] = useCompleteGameMutation();
  const [newGame] = useNewGameMutation();

  const game = {
    title,
    number_of_players: playersNum,
    stats: {
      games: [
        { id: "game1", name: "Zgodovinski preizkus", status: "open" },
        { id: "game2", name: "Preizkus stabilnosti", status: "open" },
        { id: "game3", name: "Moderni preizkus", status: "open" },
        { id: "game4", name: "Mednarodni preizkus", status: "open" },
        { id: "game5", name: "Zlati preizkus", status: "open" },
        { id: "game6", name: "Preizkus nostalgije", status: "open" },
      ],
    },
  };

  const handleCompleteGame = () => {
    const game = {};

    completeGame(game);
  };

  const handleNewGame = (e) => {
    e.preventDefault();
    setIsCreating(true);

    setTimeout(() => {
      handleCompleteGame();

      setTimeout(() => {
        newGame(game);
        setIsCreating(false);
      }, 500);
    }, 1500);
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  const goToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  console.log(data);

  const CurrentGameSummary = () => {
    return (
      <div className=" summary p-1 row g-0 mb-2">
        <div className="col d-flex align-items-center">
          <h2 className="mb-0">{data.title}</h2>
        </div>

        <div className="col text-end">
          <h2 className="mb-0">
            {data.number_of_players} / {data.users.length}
          </h2>
          <h3 className="mb-0">{data.started_on}</h3>
        </div>
        <div></div>
      </div>
    );
  };

  return (
    <div className="admin p-2">
      {isSuccess && !isCreating && (
        <div className="current-game">
          <CurrentGameSummary />

          <button
            onClick={() => goToBottom()}
            className="new-game btn btn-gold text-white mb-1 py-2 px-3 w-100 mb-4 d-flex justify-content-center">
            <strong>USTVARI NOVO IGRO +</strong>
          </button>

          <div className="row g-1">
            {data.users.map((item) => (
              <div className="col-4" key={item.name}>
                <div className="border border-1 rounded-1 p-2">
                  <h3
                    style={{ fontSize: "14px" }}
                    className="mt-0 mb-2 text-black">
                    {item.name}
                  </h3>
                  {item.settings.games.map((game) => (
                    <span
                      key={game.id}
                      className={`user-game-item badge text-white text-start px-2 py-1 mb-1 d-flex justify-content-center ${
                        game.status === "completed"
                          ? "bg-green text-white"
                          : game.status === "playing"
                          ? "bg-gold text-white"
                          : "bg-orange-light "
                      } ${game.status === "exclude" && "d-none"}`}>
                      <span className="d-block w-100">
                        {game.id}
                        <span
                          className="float-end d-inline-block fw-normal"
                          style={{ fontSize: "7px" }}>
                          {game.status}
                        </span>
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            ))}
            {
              // Then, render additional static divs if needed
              Array.from({
                length: Math.max(0, data.number_of_players - data.users.length),
              }).map((_, index) => (
                <div className="col-4" key={index}>
                  <div
                    style={{ minHeight: "200px" }}
                    className="border border-1 rounded-1 p-2 h-100 d-flex align-items-center justify-content-center game1-bg-light-30">
                    <div className="text-center">
                      {/* Static content here */}
                      <p
                        style={{ fontSize: "10px" }}
                        className="mt-0 fw-bold mb-2 text-black text-center">
                        Čakam ekipo
                      </p>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 44 44"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="#000">
                        <g fill="none" fill-rule="evenodd" stroke-width="2">
                          <circle cx="22" cy="22" r="1">
                            <animate
                              attributeName="r"
                              begin="0s"
                              dur="1.8s"
                              values="1; 20"
                              calcMode="spline"
                              keyTimes="0; 1"
                              keySplines="0.165, 0.84, 0.44, 1"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="stroke-opacity"
                              begin="0s"
                              dur="1.8s"
                              values="1; 0"
                              calcMode="spline"
                              keyTimes="0; 1"
                              keySplines="0.3, 0.61, 0.355, 1"
                              repeatCount="indefinite"
                            />
                          </circle>
                          <circle cx="22" cy="22" r="1">
                            <animate
                              attributeName="r"
                              begin="-0.9s"
                              dur="1.8s"
                              values="1; 20"
                              calcMode="spline"
                              keyTimes="0; 1"
                              keySplines="0.165, 0.84, 0.44, 1"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="stroke-opacity"
                              begin="-0.9s"
                              dur="1.8s"
                              values="1; 0"
                              calcMode="spline"
                              keyTimes="0; 1"
                              keySplines="0.3, 0.61, 0.355, 1"
                              repeatCount="indefinite"
                            />
                          </circle>
                        </g>
                        <script xmlns="" />
                      </svg>
                    </div>
                  </div>
                  {/* You can add more static content similar to the dynamic content above */}
                </div>
              ))
            }
          </div>
        </div>
      )}

      <form
        className="addTask mb-6 mt-5 w-75 mx-auto"
        name="addTask"
        onSubmit={handleNewGame}>
        <h3 className="mb-2">USTVARI NOVO IGRO</h3>
        <div>
          <input
            className="mb-3 form-control"
            type="text"
            name="title"
            onChange={(e) => setTitle(e.target.value.toUpperCase())}
            value={title}
            placeholder="Ime igre"
          />
        </div>
        <div>
          <select
            className="mb-3 form-control"
            onChange={(e) => setPlayersNum(e.target.value)}
            value={playersNum}>
            <option>Izberi število igralcev</option>
            {[...Array(10).keys()].map((num) => (
              <option key={num} value={num + 1}>
                {num + 1}
              </option>
            ))}
          </select>
        </div>
        <button
          className={`btn btn-gold text-white mb-1 py-2 px-3 w-100 ${
            playersNum === "" && "disabled"
          } ${title === "" && "disabled"}`}
          type="submit">
          Ponastavi prejšnjo igro in ustvari novo
        </button>
      </form>
      {isCreating && (
        <div className="text-center">
          <small className="d-block fw-bold">Ustvarjam igro</small>
          <svg
            width="100"
            height="100"
            viewBox="0 0 44 44"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#000">
            <g fill="none" fill-rule="evenodd" stroke-width="2">
              <circle cx="22" cy="22" r="1">
                <animate
                  attributeName="r"
                  begin="0s"
                  dur="1.8s"
                  values="1; 20"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.165, 0.84, 0.44, 1"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="stroke-opacity"
                  begin="0s"
                  dur="1.8s"
                  values="1; 0"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.3, 0.61, 0.355, 1"
                  repeatCount="indefinite"
                />
              </circle>
              <circle cx="22" cy="22" r="1">
                <animate
                  attributeName="r"
                  begin="-0.9s"
                  dur="1.8s"
                  values="1; 20"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.165, 0.84, 0.44, 1"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="stroke-opacity"
                  begin="-0.9s"
                  dur="1.8s"
                  values="1; 0"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.3, 0.61, 0.355, 1"
                  repeatCount="indefinite"
                />
              </circle>
            </g>
            <script xmlns="" />
          </svg>
        </div>
      )}
      {isLoading && <h1>NALAGAM</h1>}
      {/*       {error && !isCreating && <h1>NAPAKA - ustvarite novo igro</h1>} */}
    </div>
  );
};

export default Admin;
