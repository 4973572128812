import { useNavigate } from "react-router-dom";
function Button({ onClick, className, label, showPrev, disabled }) {
  const navigate = useNavigate();

  const clickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <button
        disabled={disabled}
        className={`btn main-button mt-3  ${className}`}
        onClick={clickHandler}>
        <span>{label}</span>
      </button>
      {showPrev === "true" && (
        <button
          onClick={() => navigate(-1)}
          className="btn btn-sm back-button mb-3 fw-bold">
          NAZAJ
        </button>
      )}
    </>
  );
}

export default Button;
