import { useContext, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showResultReducer } from "store/gameSlice";
import html2canvas from "html2canvas";
import { useGamesQuery, useUpdateTeamMutation } from "services/gameApi";

// Import styles, media and components
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

function GameBanknoteGame({ noteItems, setNoteItems }) {
  const exportRef = useRef();
  const dispatch = useDispatch();
  const { data, error, isLoading, isSuccess, refetch } = useGamesQuery();
  const token = useSelector((state) => state.user.token);
  const [updateTeam] = useUpdateTeamMutation();

  const images = importAll(
    require.context("assets/images/banknote", false, /\.(png|jpe?g|svg)$/)
  );

  const addIconHandler = (icon) => {
    if (!noteItems.icon.includes(icon) && noteItems.icon.length <= 3) {
      setNoteItems({
        ...noteItems,
        icon: [...noteItems.icon, icon],
      });
    }
  };

  const Ikone = () => {
    const showIkone = noteItems.icon.map((item, index) => {
      return (
        <div key={index} className={`icon icon__${index + 1}`}>
          <img src={images[`${item}.png`]} />
        </div>
      );
    });

    return showIkone;
  };

  const handleClick = () => {
    exportAsImage(exportRef.current, "savebanknoteimg");
    dispatch(showResultReducer(true));
  };

  const exportAsImage = async (element, imageFileName) => {
    const html = document.getElementsByTagName("html")[0];
    const body = document.getElementsByTagName("body")[0];
    let htmlWidth = html.clientWidth;
    let bodyWidth = body.clientWidth;

    const newWidth = element.scrollWidth - element.clientWidth;

    if (newWidth > element.clientWidth) {
      htmlWidth += newWidth;
      bodyWidth += newWidth;
    }

    html.style.width = htmlWidth + "px";
    body.style.width = bodyWidth + "px";

    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png", 1.0);

    const filteredTeamStatus = data.users
      .filter((i) => i.token === token)
      .map((obj) => {
        return {
          ...obj,
          settings: { ...obj.settings, avatar: image },
        };
      });

    updateTeam(filteredTeamStatus[0]);

    html.style.width = null;
    body.style.width = null;
  };

  return (
    <Wrapper
      className={` bg-gold-light w-100 p-3 shadow-wrapper w-100 banknote text-center`}>
      <div className="bg-gradient-gold shadow-wrapper">
        <h2 className="display-6 fw-bold display-6 fw-bold text-white text-uppercase p-3">
          Preizkus Poštenosti
        </h2>
      </div>
      <div className="elements bg-white bg-opacity-50 shadow-wrapper mt-3 d-flex align-items-center justify-content-center">
        <div className="d-flex justify-content-between w-100 px-6">
          <DropdownButton id="dropdown-colors" title="Barve">
            <div className="row g-0 px-4">
              <div className="col-2 p-2">
                <button
                  onClick={() =>
                    setNoteItems({ ...noteItems, color: "color1" })
                  }
                  style={{ aspectRatio: 1 }}
                  className="color1 w-100 h-100"></button>
              </div>

              <div className="col-2 p-2">
                <button
                  onClick={() =>
                    setNoteItems({ ...noteItems, color: "color2" })
                  }
                  style={{ aspectRatio: 1 }}
                  className="color2 w-100 h-100"></button>
              </div>

              <div className="col-2 p-2">
                <button
                  onClick={() =>
                    setNoteItems({ ...noteItems, color: "color3" })
                  }
                  style={{ aspectRatio: 1 }}
                  className="color3 w-100 h-100"></button>
              </div>

              <div className="col-2 p-2">
                <button
                  onClick={() =>
                    setNoteItems({ ...noteItems, color: "color4" })
                  }
                  style={{ aspectRatio: 1 }}
                  className="color4 w-100 h-100"></button>
              </div>

              <div className="col-2 p-2">
                <button
                  onClick={() =>
                    setNoteItems({ ...noteItems, color: "color5" })
                  }
                  style={{ aspectRatio: 1 }}
                  className="color5 w-100 h-100"></button>
              </div>

              <div className="col-2 p-2">
                <button
                  onClick={() =>
                    setNoteItems({ ...noteItems, color: "color6" })
                  }
                  style={{ aspectRatio: 1 }}
                  className="color6 w-100 h-100"></button>
              </div>
            </div>
          </DropdownButton>
          <DropdownButton id="dropdown-icons" title="Ikone">
            {noteItems.icon.length === 4 && (
              <Button
                className="btn-reset mt-0 mb-0"
                label="Izberite še enkrat"
                onClick={() => setNoteItems({ ...noteItems, icon: [] })}
              />
            )}
            <div
              className={`${
                noteItems.icon.length === 4 && "opacity-25 pe-none"
              }`}>
              <div className="row px-4">
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon1")}
                    className="w-100 h-100">
                    <img src={images[`icon1.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon2")}
                    className="w-100 h-100">
                    <img src={images[`icon2.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon3")}
                    className="w-100 h-100">
                    <img src={images[`icon3.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon4")}
                    className="w-100 h-100">
                    <img src={images[`icon4.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon5")}
                    className="w-100 h-100">
                    <img src={images[`icon5.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon6")}
                    className="w-100 h-100">
                    <img src={images[`icon6.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon7")}
                    className="w-100 h-100">
                    <img src={images[`icon7.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon8")}
                    className="w-100 h-100">
                    <img src={images[`icon8.svg`]} />
                  </button>
                </div>
              </div>
              <div className="row px-4">
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon9")}
                    className="w-100 h-100">
                    <img src={images[`icon9.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon10")}
                    className="w-100 h-100">
                    <img src={images[`icon10.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon11")}
                    className="w-100 h-100">
                    <img src={images[`icon11.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon12")}
                    className="w-100 h-100">
                    <img src={images[`icon12.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon13")}
                    className="w-100 h-100">
                    <img src={images[`icon13.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon14")}
                    className="w-100 h-100">
                    <img src={images[`icon14.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon15")}
                    className="w-100 h-100">
                    <img src={images[`icon15.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon16")}
                    className="w-100 h-100">
                    <img src={images[`icon16.svg`]} />
                  </button>
                </div>
              </div>
              <div className="row px-4">
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon17")}
                    className="w-100 h-100">
                    <img src={images[`icon17.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon18")}
                    className="w-100 h-100">
                    <img src={images[`icon18.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon19")}
                    className="w-100 h-100">
                    <img src={images[`icon19.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon20")}
                    className="w-100 h-100">
                    <img src={images[`icon20.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon21")}
                    className="w-100 h-100">
                    <img src={images[`icon21.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon22")}
                    className="w-100 h-100">
                    <img src={images[`icon22.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon23")}
                    className="w-100 h-100">
                    <img src={images[`icon23.svg`]} />
                  </button>
                </div>
                <div className="col p-2">
                  <button
                    onClick={() => addIconHandler("icon24")}
                    className="w-100 h-100">
                    <img src={images[`icon24.svg`]} />
                  </button>
                </div>
              </div>
            </div>
          </DropdownButton>

          <DropdownButton id="dropdown-elements" title="Elementi">
            <div className="row px-1">
              <div className="col-9">
                <div className="row g-0">
                  <div className="col-4 text-center">
                    <div className="crtice">
                      <div className="w-100 mb-2">
                        <p className="small fw-bold">
                          Izbočene črtice ob robovih
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, crtice: "1" })
                        }>
                        <img src={images[`crtice1_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, crtice: "2" })
                        }>
                        <img src={images[`crtice2_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, crtice: "3" })
                        }>
                        <img src={images[`crtice3_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, crtice: "4" })
                        }>
                        <img src={images[`crtice4_m.svg`]} />
                      </button>
                    </div>
                    <div className="portret">
                      <div className="w-100 mb-2">
                        <p className="small fw-bold">
                          Portret boginje
                          <br />
                          Evrope
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, portret: "1" })
                        }>
                        <img src={images[`portret1_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, portret: "2" })
                        }>
                        <img src={images[`portret2_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, portret: "3" })
                        }>
                        <img src={images[`portret3_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, portret: "4" })
                        }>
                        <img src={images[`portret4_m.svg`]} />
                      </button>
                    </div>
                  </div>
                  <div className="col-4 text-center">
                    <div className="nit">
                      <div className="w-100 mb-2">
                        <p className="small fw-bold">
                          Varnostna
                          <br />
                          nit
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, nit: "1" })
                        }>
                        <img src={images[`nit1_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, nit: "2" })
                        }>
                        <img src={images[`nit2_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, nit: "3" })
                        }>
                        <img src={images[`nit3_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, nit: "4" })
                        }>
                        <img src={images[`nit4_m.svg`]} />
                      </button>
                    </div>
                    <div className="arhitekt">
                      <div className="w-100 mb-2">
                        <p className="small fw-bold">
                          Arhitekturni
                          <br />
                          motiv
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, arhitekt: "1" })
                        }>
                        <img src={images[`arhitekt1_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, arhitekt: "2" })
                        }>
                        <img src={images[`arhitekt2_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, arhitekt: "3" })
                        }>
                        <img src={images[`arhitekt3_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, arhitekt: "4" })
                        }>
                        <img src={images[`arhitekt4_m.svg`]} />
                      </button>
                    </div>
                  </div>
                  <div className="col-4 text-center">
                    <div className="vodni">
                      <div className="w-100 mb-2">
                        <p className="small fw-bold">
                          Vodni
                          <br />
                          žig
                        </p>
                      </div>

                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, vodniznak: "1" })
                        }>
                        <img src={images[`vodniznak1_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, vodniznak: "2" })
                        }>
                        <img src={images[`vodniznak2_m.svg`]} />
                      </button>
                      <br />
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, vodniznak: "3" })
                        }>
                        <img src={images[`vodniznak3_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, vodniznak: "4" })
                        }>
                        <img src={images[`vodniznak4_m.svg`]} />
                      </button>
                    </div>
                    <div className="arhitekt">
                      <div className="w-100 mb-2">
                        <p className="small fw-bold">
                          Mavrične
                          <br />
                          krivulje
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, mavricne: "1" })
                        }>
                        <img src={images[`mavricne1_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, mavricne: "2" })
                        }>
                        <img src={images[`mavricne2_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, mavricne: "3" })
                        }>
                        <img src={images[`mavricne3_m.svg`]} />
                      </button>
                      <button
                        onClick={() =>
                          setNoteItems({ ...noteItems, mavricne: "4" })
                        }>
                        <img src={images[`mavricne4_m.svg`]} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-3 text-center">
                <div className="stevilka">
                  <div className="w-100 mb-2">
                    <p className="small fw-bold">
                      Svetleča in izbočena številka
                    </p>
                  </div>
                  <button
                    className="p-1"
                    onClick={() =>
                      setNoteItems({ ...noteItems, stevilka: "5" })
                    }>
                    <img src={images[`5_m.svg`]} />
                  </button>
                  <button
                    className="p-1"
                    onClick={() =>
                      setNoteItems({ ...noteItems, stevilka: "10" })
                    }>
                    <img src={images[`10_m.svg`]} />
                  </button>
                  <button
                    className="p-1"
                    onClick={() =>
                      setNoteItems({ ...noteItems, stevilka: "20" })
                    }>
                    <img src={images[`20_m.svg`]} />
                  </button>
                  <button
                    className="p-1"
                    onClick={() =>
                      setNoteItems({ ...noteItems, stevilka: "50" })
                    }>
                    <img src={images[`50_m.svg`]} />
                  </button>
                  <button
                    className="p-1"
                    onClick={() =>
                      setNoteItems({ ...noteItems, stevilka: "100" })
                    }>
                    <img src={images[`100_m.svg`]} />
                  </button>
                  <button
                    className="p-1"
                    onClick={() =>
                      setNoteItems({ ...noteItems, stevilka: "200" })
                    }>
                    <img src={images[`200_m.svg`]} />
                  </button>
                  <button
                    className="p-1"
                    onClick={() =>
                      setNoteItems({ ...noteItems, stevilka: "500" })
                    }>
                    <img src={images[`500_m.svg`]} />
                  </button>
                </div>
              </div>
            </div>
          </DropdownButton>
        </div>
      </div>

      <div className="bg-white position-relative bg-opacity-50 h-100 shadow-wrapper mt-3 d-flex align-items-center justify-content-center">
        <div>
          <h4
            className="position-absolute text-gold opacity-75"
            style={{ top: "40px", left: "50%", transform: "translate(-50%)" }}>
            V zgornjem meniju izberite elemente, ikone in barve.
          </h4>

          <div
            className="position-absolute"
            style={{
              bottom: "20px",
              left: "50%",
              transform: "translate(-50%)",
            }}>
            <div
              className={`note ${
                noteItems.color === "" ? "color5" : noteItems.color
              }`}
              ref={exportRef}>
              <Ikone />
              <div
                className={`crtice crtice__left crtice__${noteItems.crtice}`}
              />
              <div
                className={`crtice crtice__right crtice__${noteItems.crtice}`}
              />
              <div className={`stevilka stevilka__${noteItems.stevilka}`}></div>
              <div
                className={`stevilka-color stevilka-color__${noteItems.stevilka}`}></div>

              <div className={`nit nit__${noteItems.nit}`} />

              <div className={`arhitekt arhitekt__${noteItems.arhitekt}`} />

              <div className={`mavricne mavricne__${noteItems.mavricne}`} />

              <div
                className={`mavricne-blank mavricne-blank__${noteItems.mavricne}`}
              />

              <div className={`portret portret__${noteItems.portret}`} />

              <div className={`vodniznak vodniznak__${noteItems.vodniznak}`} />
            </div>
            <Button
              label="NAPREJ"
              className="mx-auto mb-5 absolute-button px-6"
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

export default GameBanknoteGame;
